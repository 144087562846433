import useSWR from "swr";
import { earn } from "services";
import { decryptPath, truoraPath } from "services/paths";

const urlDecrypt =
  process.env.REACT_APP_API_BASE_URL +
  process.env.REACT_APP_API_BASE_PATH +
  truoraPath +
  decryptPath;
const params = {
  country: "CO",
};

export function useDecrypt({ token }) {
  const url = `${urlDecrypt}${token}`;
  const { data, error, mutate } = useSWR([url, params], earn);
  const isLoading = !error && !data;
  const isError = Boolean(error);
  return {
    data,
    isLoading,
    isError,
    mutate,
  };
}
