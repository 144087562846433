export class Coordinates {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

export class Size {
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }
}

export class Value {
  constructor(lilac, red, purple, blue) {
    this.lilac = lilac;
    this.red = red;
    this.purple = purple;
    this.blue = blue;
  }
}
