import { FormData } from "./models/affidavitMxSlice.model";

export const formState: FormData = {
  civil_state: {
    title: "Estado civil",
    subTitle: "",
    state: [
      {
        order: "h",
        type: "radio_btn",
        options: [
          {
            value: `Soltero`,
            label: "Soltero",
          },
          {
            value: `Casado por sociedad conyugal`,
            label: "Casado por sociedad conyugal",
          },
          {
            value: `Casado por bienes separados`,
            label: "Casado por bienes separados",
          },
          {
            value: `Divorciado`,
            label: "Divorciado",
          },
          {
            value: `Separación en proceso judicial`,
            label: "Separación en proceso judicial",
          },
          {
            value: `Viudo`,
            label: "Viudo",
          },
          {
            value: `Concubinato`,
            label: "Concubinato",
          },
        ],
        name: "civil_state",
        helper: {
          link: "",
          example:
            "https://amatertasu-docs-images.s3.amazonaws.com/estados_civiles.pdf",
          label: "¿Qué es esto?",
          tooltip: true,
          content: `<strong style="font-size: 17px; mix-blend-mode: difference;">Soltero:</strong> Que nunca ha contraído matrimonio. 
          <span style="height: 10px; display: flex;">\t</span>
          <strong style="font-size: 17px; mix-blend-mode: difference;">Casado bajo el régimen de sociedad conyugal o Casado bajo el régimen de separación de bienes:</strong> En la imagen adjunta se especifica la parte del Acta de matrimonio en la que se puede consultar la información.`,
        },
        label: "¿Cuál es tu estado civil?",
        variant: "square",
      },
    ],
  },
  childs: {
    title: "Sobre RFC",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: [
          {
            value: `yes`,
            label: "Si",
          },
          {
            value: `no`,
            label: "No",
          },
        ],
        name: "rfc",
        label:
          "¿Te encuentras inscrito y con estado activo en el Registro Federal de Contribuyentes (RFC)?",
        variant: "rounded",
        sizeComponent: "102px",
        helper: {
          link: "",
          label: "¿Qué es esto?",
          tooltip: true,
          example:
            "https://amatertasu-docs-images.s3.amazonaws.com/Registro+Federal+de+Contribuyentes.pdf",
          content:
            'Es la clave que otorga el Sistema de Administración Tributaria (SAT) a toda persona física y moral que realiza una actividad económica por la que está obligado a realizar el pago de impuestos, incluida la compra-venta de inmuebles, es importante que este en estado "Activo".',
        },
      },
    ],
  },
  contact_data: {
    title: "Datos de contacto",
    subTitle: "Por favor proporciónanos dos personas de contacto:",
    state: [
      {
        type: "input",
        placeholder: "Nombre",
        name: "contact_data_name_1",
        label: "Nombre 1",
        variant: "rounded",
      },
      {
        type: "input",
        placeholder: "Teléfono",
        name: "contact_data_phone_1",
        label: "Telefono 1",
        variant: "rounded",
        typeField: "number",
      },
      {
        type: "input",
        placeholder: "Nombre",
        name: "contact_data_name_2",
        label: "Nombre 2",
        variant: "rounded",
      },
      {
        type: "input",
        placeholder: "Teléfono",
        name: "contact_data_phone_2",
        label: "Telefono 2",
        variant: "rounded",
        typeField: "number",
      },
    ],
  },
  about_property: {
    title: "Sobre el inmueble",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: [
          {
            value: `yes`,
            label: "Si",
          },
          {
            value: `no`,
            label: "No",
          },
        ],
        name: "about_property",
        label: "¿Tu inmueble esta rentado en este momento?",
        variant: "rounded",
        sizeComponent: "140px",
        componentsChilds: [
          {
            order: "v",
            type: "date_picker",
            value: new Date().toISOString(),
            options: [
              {
                value: `yes`,
                label: "Si",
              },
              {
                value: `no`,
                label: "No",
              },
            ],
            name: "about_property_date",
            label: "¿Hasta que fecha estará rentado?",
            subLabel:
              "Esta fecha es muy importante para el proceso, trata de ser lo mas preciso posible.",
            variant: "rounded",
            placeholder: "DD/MM/AAAA",
          },
        ],
      },
    ],
  },
  about_property_2: {
    title: " ",
    state: [
      {
        order: "h",
        type: "radio_btn",
        options: [
          {
            value: `Escritura pública`,
            label: "Escritura pública",
          },
          {
            value: `Copia simple`,
            label: "Copia simple",
          },
          {
            value: `No tengo ninguna`,
            label: "No tengo ninguna",
          },
        ],
        name: "simple_document",
        helper: {
          link: "",
          example:
            "https://amatertasu-docs-images.s3.amazonaws.com/EJEMPLOS+ESCITURAS.pdf",
          content: `<strong style="font-size: 17px; mix-blend-mode: difference;">Escritura pública:</strong> Es el documento que entrega el Notario ante quien se realizó la compraventa y que está inscrita ante las instituciones registrales que acreditan la titularidad del inmueble (Debe tener sellos y quinegramas impresos en los folios). 
          <span style="height: 10px; display: flex;">\t</span>
          <strong style="font-size: 17px; mix-blend-mode: difference;">Copia simple:</strong> Es la reproducción del documento que el Notario realizó para formalizar la compraventa (Carece de sellos y quinegramas impresos en los folios que permitan asegurar la titularidad), generalmente se encuentra impreso en papel bond.`,
          label: "¿Qué es esto?",
          tooltip: true,
        },
        label: "¿Cuentas con escrituras públicas y/o copia simple?",
        variant: "square",
      },
      {
        order: "v",
        type: "radio_btn",
        options: [
          {
            value: `yes`,
            label: "Si",
          },
          {
            value: `no`,
            label: "No",
          },
        ],
        name: "actual_process",
        label:
          "¿El inmueble está actualmente en un proceso de Adjudicación, Sucesión, Divorcio, Intestado u otro carácter judicial?",
        variant: "rounded",
        sizeComponent: "102px",
        helper: {
          link: "",
          label: "¿Qué es esto?",
          tooltip: true,
          content: `Cualquier proceso del que tiene conocimiento un Juez y que tiene que resolverse a través de una sentencia que determine quién es el titular del inmueble.`,
        },
      },
    ],
  },
};
