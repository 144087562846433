import { useEffect, useState } from "react";
//Components
import { Button } from "@habitech/call-to-action";
import TermsCo from "./components/TermsCo/TermsCo";
import TermsMx from "./components/TermsMx/TermsMx";

//Styles
import { TermsAndConditionsWrapper } from "./styles";

// Helpers
import { valuesFormMx, valuesFormCo } from "./valuesForm";
import AcceptTermsAndConditionsCo from "./components/AcceptTermsAndConditionsCo/AcceptTermsAndConditionsCo";
import AcceptTermsAndConditionsMx from "./components/AcceptTermsAndConditionsMx/AcceptTermsAndConditionsMx";
import AuthorizationParagraphCo from "./components/AuthorizationParagraphCo/AuthorizationParagraphCo";
import AuthorizationParagraphMx from "./components/AuthorizationParagraphMx/AuthorizationParagraphMx";
import Clarifications from "./components/Clarifications/Clarifications";

// Model

const TermsAndConditions = ({ handleChange, loading, handleEdit, data }) => {
  const [form, setForm] = useState({});
  const getFormByCountry = () => {
    return (
      {
        MX: valuesFormMx,
      }[data?.country || ""] || valuesFormCo
    );
  };

  useEffect(() => {
    setForm(getFormByCountry());
  }, [data?.country]);

  const allValuesAreFill = () => {
    return !Object.keys(form).every((key) => form[key] !== "");
  };

  const getTermsComponent = () => {
    return (
      {
        MX: <TermsMx data={data} />,
      }[data?.country || ""] || <TermsCo data={data} />
    );
  };

  const getFormComponent = () => {
    return (
      {
        MX: (
          <AcceptTermsAndConditionsMx
            form={getFormByCountry()}
            handleChange={setForm}
            data={data}
          />
        ),
      }[data?.country || ""] || (
        <AcceptTermsAndConditionsCo handleChange={setForm} />
      )
    );
  };

  const getAuthParagraph = () => {
    return (
      {
        MX: (
          <>
            <Clarifications />
            <AuthorizationParagraphMx />
          </>
        ),
      }[data?.country || ""] || <AuthorizationParagraphCo />
    );
  };

  const submitForm = () => {
    const acceptTermsData = form;
    handleChange(acceptTermsData);
  };

  return (
    <TermsAndConditionsWrapper>
      {getTermsComponent()}
      <div className="button-edit-container">
        <Button
          dataId="edit-fields-buttons"
          type="button"
          variant="stroke"
          onClick={() => {
            handleEdit(true);
          }}
          size="large"
        >
          Editar Información
        </Button>
      </div>
      {getFormComponent()}
      {getAuthParagraph()}

      <div className="button-submit-container">
        <Button
          dataId="login-button"
          loading={Boolean(loading)}
          type="button"
          onClick={() => {
            submitForm();
          }}
          size="large"
          disabled={allValuesAreFill()}
          iconRight={
            <svg
              width="20"
              fill="none"
              height="20"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="white"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.5612 4.984C16.7737 5.16042 16.8029 5.47565 16.6265 5.68809L8.66299 15.2779C8.06319 16.0002 6.95481 16.0002 6.35501 15.2779L3.38171 11.6974C3.20529 11.4849 3.2345 11.1697 3.44694 10.9933C3.65939 10.8169 3.97462 10.8461 4.15103 11.0585L7.12434 14.639C7.32427 14.8798 7.69373 14.8798 7.89366 14.639L15.8571 5.04924C16.0336 4.83679 16.3488 4.80759 16.5612 4.984Z"
              />
            </svg>
          }
        >
          Enviar aceptación
        </Button>
      </div>
    </TermsAndConditionsWrapper>
  );
};

export default TermsAndConditions;
