import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { ReactComponent as Lilac } from "assets/loader5.svg";
import { ReactComponent as Red } from "assets/loader6.svg";
import { ReactComponent as Purple } from "assets/loader7.svg";
import { ReactComponent as Blue } from "assets/loader8.svg";
import { Value } from "./models";

const time = 0.6;
const Load = ({
  lilacList,
  redList,
  purpleList,
  blueList,
  lilacSize,
  redSize,
  purpleSize,
  blueSize,
  initialValue,
  widthGlobal,
  heightGlobal,
}) => {
  const [rotate, setRotate] = useState(initialValue);

  useEffect(() => {
    const id = setInterval(() => {
      const lilac = lilacList.rotate(-1).pointer.value;
      const red = redList.rotate(-1).pointer.value;
      const purple = purpleList.rotate(-1).pointer.value;
      const blue = blueList.rotate(-1).pointer.value;
      const newValue = new Value(lilac, red, purple, blue);
      setRotate(newValue);
    }, time * 1000);
    return () => clearInterval(id);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50%",
      }}
    >
      <div
        style={{
          display: "grid",
          width: `${widthGlobal}px`,
          height: `${heightGlobal}px`,
          gridTemplate: "1fr 1fr / 1fr 1fr",
        }}
      >
        <motion.div
          animate={rotate.purple}
          style={{ width: `${purpleSize.width}px` }}
          transition={{ ease: "circOut", duration: time }}
        >
          <Purple width={purpleSize.width} height={purpleSize.height} />
        </motion.div>
        <motion.div
          animate={rotate.red}
          style={{ width: `${redSize.width}px` }}
          transition={{ ease: "circOut", duration: time }}
        >
          <Red width={redSize.width} height={redSize.height} />
        </motion.div>

        <motion.div
          animate={rotate.lilac}
          style={{ width: `${lilacSize.width}px` }}
          transition={{ ease: "circOut", duration: time }}
        >
          <Lilac width={lilacSize.width} height={lilacSize.height} />
        </motion.div>
        <motion.div
          animate={rotate.blue}
          style={{ width: `${blueSize.width}px` }}
          transition={{ ease: "circOut", duration: time }}
        >
          <Blue width={blueSize.width} height={blueSize.height} />
        </motion.div>
      </div>
    </div>
  );
};

export default Load;
