import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { postAffidavitFormMx } from "services/paths";

const urlForm =
  process.env.REACT_APP_AFFIDAVIT_BASE_URL_MX +
  process.env.REACT_APP_API_BASE_PATH;
const params = {
  country: "MX",
};

const apiKey = process.env.REACT_APP_AFFIDAVIT_API_KEY;

export const affidavitMxApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: urlForm }),
  endpoints: (builder) => ({
    addNewFormPost: builder.mutation({
      query: (payload) => ({
        url: postAffidavitFormMx,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-api-key": apiKey,
        },
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useAddNewFormPostMutation } = affidavitMxApi;
