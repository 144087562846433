import React from "react";
import notFound from "assets/notFound.png";
import "./styles.css";

const Error = () => {
  return (
    <div className="notFound">
      <div className="notFound_picture">
        <img className="notFound_picture_image" src={notFound} alt="error" />
      </div>
      <div className="notFound_content">
        <h1 className="notFound_content_title">
          mmmm... Algo malo ocurrió con el servicio.
        </h1>
      </div>
    </div>
  );
};

export default Error;
