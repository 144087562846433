import { FC } from "react";

// Components
import logo from "../../../../../assets/tu-habi-purple.svg";

// Styles

import { HeaderWrapper } from "../HeaderWrapper";

// helpers

const HeaderMx = () => {
  return (
    <HeaderWrapper>
      <div className="header-logo">
        <img src={logo} className="header-logo-image " alt="logo" />
        <h5 className="header-logo-text">
          <p>Corporativo MCNEMéxico, S. de R.L. de C.V.</p>
          <p>RFC: CMC210701L19</p>
          <p>Avenida Paseo de la Reforma 296, Piso 35.</p>
        </h5>
      </div>
      <div className="header-title">
        <h4>Solicitud de Compra</h4>
      </div>
    </HeaderWrapper>
  );
};

export default HeaderMx;
