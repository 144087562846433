import { spanishViridian600 } from "@habitech/shared";
import styled from "styled-components";

export const AuthorizationParagraphWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .paragraph {
    font: 400 12px Montserrat;
    line-height: 20px;
    color: ${spanishViridian600};
    margin: 0 0 24px;
  }
  .content-policies-link {
    color: #3483fa;
  }
`;
