import styled from "styled-components";
import { scarpaGray800 } from "@habitech/shared";

export const FormWrapper = styled.form`
  font: 400 14px Montserrat;
  .footer_form_terms {
    padding: 0;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 0 0 42px;
    .form-item {
      display: flex;
      align-items: center;
    }
    .form-item-radio-buttons {
      .label-field {
        margin-bottom: 12px;
      }
      .radio-buttons-container {
        display: flex;
        width: 400px;
        align-items: center;
        gap: 12px;
      }
    }
  }
  .last-item {
    margin-bottom: 32px;
  }
  .selects-container {
    width: 100%;
  }
  /* Ocultar los inputs de radio originales */
  input[type="radio"] {
    cursor: pointer;
  }

  .footer_form_checkbox {
    display: inline-block;
    margin-right: 12px;
    width: 20px;
    height: 20px;
  }
  .footer_form_label {
    display: block;
    color: ${scarpaGray800};
    margin: 4px 0 0 0;
  }
  .footer_form_link {
    display: block;
    color: #3483fa;
  }
  .buttons-container {
    display: flex;
    gap: 16px;
  }
`;
export const LoaderContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  opacity: 100%;
`;
