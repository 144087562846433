import React from "react";
import { SuccessTruoraWrapper } from "./styles";

import HabiMxLogo from "assets/tuhabi-certificate-logo.svg";
import HabiLogo from "assets/habi-certificate-logo.svg";
import { useParams } from "react-router-dom";

const SuccessTruora = () => {
  const { country = "" } = useParams();

  const getCompanyNameByCountry = () => {
    return (
      {
        MX: "Tuhabi",
      }[country || ""] || "Habi"
    );
  };

  const getLogoByCountry = () => {
    return (
      {
        MX: HabiMxLogo,
      }[country || ""] || HabiLogo
    );
  };
  return (
    <SuccessTruoraWrapper>
      <img
        src={getLogoByCountry()}
        className={`bg-logo ${getCompanyNameByCountry()}`}
        alt="Logo-circular-habi"
      />
      <header className="header">
        <p className="header-title">Aceptación confirmada</p>
        <p className="header-success-message">
          Muy bien! tu aceptación ha sido enviada exitosamente
        </p>
      </header>
      <div className="detail">
        <p className="detail-main">
          Si tu solicitud de compra la estas haciendo con varias personas
          quedará confirmada cuando las otras personas tambien acepten la
          documentación enviada. Tu asesor comercial se comunicará contigo para
          explicarte los siguientes pasos.
        </p>
        <p className="detail-footer">
          Estamos más cerca del sueño de tener tu nueva casa. Recuerda, es
          simple, es seguro, es {getCompanyNameByCountry()}.
        </p>
      </div>
    </SuccessTruoraWrapper>
  );
};

export default SuccessTruora;
