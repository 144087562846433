import styled from "styled-components";
import { electricPurple000, scarpaGray100, size } from "@habitech/shared";

export const HomeWrapper = styled.div`
  padding: 15px;

  @media screen and (min-width: ${size.tablet.min}) {
    width: 1024px;
    margin: 24px auto;
    padding: 64px 112px;
    border-radius: 8px;
    box-sizing: border-box;
    background-color: ${electricPurple000};
    border: 1px solid ${scarpaGray100};
  }
`;
