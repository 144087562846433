import Load from "./Container";
import Circularray from "circularray";
import { Coordinates, Value, Size } from "./models";

const Loader = ({ scale }) => {
  const getScale = (SCALE) => (SIZE) => Math.floor(SCALE * SIZE);
  const trans = getScale(scale);
  const lilac1 = new Coordinates(trans(150), trans(-45));
  const lilac2 = new Coordinates(trans(150), trans(-155));
  const lilac3 = new Coordinates(trans(0), trans(-205));
  const lilac4 = new Coordinates(trans(0), trans(-45));
  const lilacList = new Circularray();
  lilacList.push(lilac1);
  lilacList.push(lilac2);
  lilacList.push(lilac3);
  lilacList.push(lilac4);

  const blue1 = new Coordinates(trans(0), trans(-210));
  const blue2 = new Coordinates(trans(-150), trans(-160));
  const blue3 = new Coordinates(trans(-150), trans(0));
  const blue4 = new Coordinates(trans(0), trans(0));
  const blueList = new Circularray();
  blueList.push(blue1);
  blueList.push(blue2);
  blueList.push(blue3);
  blueList.push(blue4);

  const red1 = new Coordinates(trans(-150), trans(0));
  const red2 = new Coordinates(trans(-150), trans(150));
  const red3 = new Coordinates(trans(0), trans(150));
  const red4 = new Coordinates(trans(0), trans(0));
  const redList = new Circularray();
  redList.push(red1);
  redList.push(red2);
  redList.push(red3);
  redList.push(red4);

  const purple1 = new Coordinates(trans(0), trans(150));
  const purple2 = new Coordinates(trans(150), trans(200));
  const purple3 = new Coordinates(trans(150), trans(50));
  const purple4 = new Coordinates(trans(0), trans(20));
  const purpleList = new Circularray();
  purpleList.push(purple1);
  purpleList.push(purple2);
  purpleList.push(purple3);
  purpleList.push(purple4);

  const lilacSize = new Size(trans(134), trans(195));
  const redSize = new Size(trans(135), trans(141));
  const purpleSize = new Size(trans(136), trans(79));
  const blueSize = new Size(trans(156), trans(151));

  const initialValue = new Value(lilac1, red1, purple1, blue1);
  const widthGlobal = trans(300);
  const heightGlobal = trans(300);

  return (
    <Load
      lilacList={lilacList}
      redList={redList}
      purpleList={purpleList}
      blueList={blueList}
      lilacSize={lilacSize}
      redSize={redSize}
      purpleSize={purpleSize}
      blueSize={blueSize}
      initialValue={initialValue}
      widthGlobal={widthGlobal}
      heightGlobal={heightGlobal}
    />
  );
};

export default Loader;
