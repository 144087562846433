import styled from "styled-components";
import {
  COLORS_LABEL,
  FONT_FAMILY,
  FONT_SIZES,
  FONT_WEIGHTS,
} from "./constants/stylesByInput";

export const LabelWrapper = styled.span<any>`
  font-family: ${({ family }) => FONT_FAMILY[family]};
  font-size: ${({ size }) => `${FONT_SIZES[size]}px`};
  font-weight: ${({ weight }) => FONT_WEIGHTS[weight]};
  color: ${({ colorLabel }) => COLORS_LABEL[colorLabel]};
  margin: 0%;
`;
