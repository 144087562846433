import { size } from "@habitech/shared";
import styled from "styled-components";

export const SuccessTruoraWrapper = styled.section`
  padding: 0 16px;
  padding-top: 64px;
  margin: 0 auto;
  position: relative;
  img {
    position: absolute;
    right: 16%;
    top: 0px;
    transform: translate(50%, 50%);
  }

  .Tuhabi {
    width: 80px;
    height: 80px;
    top: 32px;
  }
  @media screen and (min-width: ${size.tablet.min}) {
    width: 389px;
    img {
      right: 32px;
    }
  }
  .header {
    &-title {
      font: 600 10px Montserrat;
      margin-bottom: 8px;
      color: #009175;
      letter-spacing: 2px;
      text-transform: uppercase;
    }
    &-success-message {
      font: 600 24px Montserrat;
      margin-bottom: 8px;
      color: #252129;
      line-height: 32px;
    }
  }
  .detail {
    font: 400 16px Montserrat;
    color: #006647;
    line-height: 24px;
    &-detail-main {
      margin-bottom: 22px;
    }
  }
`;
