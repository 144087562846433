import {
  scarpaGray300,
  scarpaGray500,
  scarpaGray800,
  spanishViridian600,
  electricPurple500,
} from "@habitech/shared";

export const FONT_FAMILY = {
  montserrat: "Montserrat",
  roboto: "Roboto",
};

export const FONT_SIZES = {
  "s-10": 10,
  "s-14": 14,
  "s-16": 16,
  "s-24": 24,
  "s-32": 32,
};

export const FONT_WEIGHTS = {
  "w-400": 400,
  "w-600": 600,
  "w-700": 700,
};

export const COLORS_LABEL = {
  scarpaGray300,
  scarpaGray500,
  scarpaGray800,
  spanishViridian600,
  electricPurple500,
};
