export const formatDateCo = (date: string) => {
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  } as const;
  const dateObject = new Date(date);
  const userTimezoneOffset = dateObject.getTimezoneOffset() * 60000;
  const dateToFormat = new Date(dateObject.getTime() + userTimezoneOffset);
  return new Intl.DateTimeFormat('es-Co', options).format(dateToFormat);
};
