import { createBrowserRouter } from "react-router-dom";
import { lazy } from "react";
//Components
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import SuccessTruora from "pages/SuccessTruora/SuccessTruora";
import PaymentGatewayWompi from "pages/PaymentGatewayWompi/PaymentGatewayWompi";

// Constants
import { ALL_ROUTES } from "constants/routes";

import WompiPaymentSuccess from "pages/WompiPaymentSuccess/WompiPaymentSuccess";

const Affidavit = lazy(() => import("pages/Affidavit"));
const AffidavitMx = lazy(() => import("pages/Affidavit/AffidavitMx"));

export default createBrowserRouter([
  {
    path: ALL_ROUTES.INDEX,
    element: <NotFound />,
  },
  {
    path: ALL_ROUTES.TRUORA,
    element: <Home />,
  },
  {
    path: `${ALL_ROUTES.TRUORA}/:token`,
    element: <Home />,
  },
  {
    path: `${ALL_ROUTES.SUCCESS_PROGRESS}/:country`,
    element: <SuccessTruora />,
  },
  {
    path: ALL_ROUTES.PAYMENT_GATEWAY_WOMPI,
    element: <PaymentGatewayWompi />,
  },
  {
    path: ALL_ROUTES.PAYMENT_WOMPI_SUCCESS,
    element: <WompiPaymentSuccess />,
  },
  {
    path: `${ALL_ROUTES.AFFIDAVIT}/:token`,
    element: <Affidavit />,
  },
  {
    path: `${ALL_ROUTES.AFFIDAVIT_MX}/:token`,
    element: <AffidavitMx />,
  },
]);
