import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";

// Components
import Label from "components/Label/Label";
import { Button } from "@habitech/call-to-action";
import DynamicForm from "components/DynamicForm/DynamicForm";

// Styles
import { ConfirmDataWrapper } from "./styles";

// helpers
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSchemaByConfig } from "components/DynamicForm/helpers/createSchemaByConfig";
import { getDefaultValuesByConfig } from "components/DynamicForm/helpers/getDefaultValuesByConfig";

// Model
import { IConfig, IDataTruora } from "models";

import { getDefaultMatchValues } from "./config/getDefaultMatchValues";
import { mergeNewDataAndOldData } from "./config/mergeNewDataAndOldData";

interface ConfirmDataProps {
  data: IDataTruora;
  handleChangeData: Dispatch<SetStateAction<any>>;
  isEditData: Dispatch<SetStateAction<boolean>>;
  formConfig: IConfig[];
}

const ConfirmData: FC<ConfirmDataProps> = ({
  data,
  handleChangeData,
  isEditData,
  formConfig,
}) => {
  const [loading, setLoading] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(true);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");

    function handleResize() {
      setIsSmallScreen(mediaQuery.matches);
    }

    handleResize();

    mediaQuery.addEventListener("change", handleResize);

    return () => {
      mediaQuery.removeEventListener("change", handleResize);
    };
  }, []);
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSchemaByConfig(formConfig)),
    defaultValues: getDefaultValuesByConfig(
      getDefaultMatchValues(formConfig, data)
    ),
  });

  const { nombre_del_cliente, nombre_de_edificio_conjunto } = data;

  const saveData = async (editData) => {
    setLoading(true);
    try {
      const dataMerged: IDataTruora = mergeNewDataAndOldData(data, editData);
      isEditData(false);
      handleChangeData(dataMerged);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ConfirmDataWrapper>
      <main>
        <div className="question">
          <Label fontSize="s-14">
            Hola {nombre_del_cliente}, estamos a un paso de legalizar tu oferta
            por el inmueble <strong>{nombre_de_edificio_conjunto}</strong>, por
            favor confirmamos que tus datos son correctos.
          </Label>
        </div>
        <div className="info-container">
          <form onSubmit={handleSubmit(saveData)}>
            <DynamicForm
              control={control}
              formConfig={getDefaultMatchValues(formConfig, data)}
              errors={errors}
              setValue={setValue}
              numberOfColumns={isSmallScreen ? 1 : 2}
            />
            <Button
              dataId="edit-data-button"
              variant="primary"
              loading={loading}
              type="submit"
            >
              Guardar y continuar
            </Button>
          </form>
        </div>
      </main>
    </ConfirmDataWrapper>
  );
};

export default ConfirmData;
