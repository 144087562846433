import axios from "axios";

const apikey = process.env.REACT_APP_HABI_360_API_KEY;
const rootUrl =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_API_BASE_PATH;

export default axios.create({
  baseURL: rootUrl,
  responseType: "json",
  headers: {
    "x-api-key": apikey,
    "Content-Type": "application/json",
  },
});
