import { FC } from "react";

// Components

// Styles

import { ClarificationsWrapper } from "./styles";

// helpers

interface ClarificationsProps {}

const Clarifications: FC<ClarificationsProps> = () => {
  return (
    <ClarificationsWrapper>
      <h3 className="title">Aclaraciones</h3>
      <div className="points-container">
        <ul className="clarifications-list">
          <li className="clarification-item">
            <p>
              Si Usted planea adquirir el inmueble a través de Infonavit
              tradicional, tenga en cuenta que debe contar con los recursos
              propios para poder asumir los gastos de escrituración, que son
              aproximadamente el 8% del valor del inmueble.
            </p>
          </li>
          <li className="clarification-item">
            <p>
              Tal y como se le presentó el inmueble al momento en que realizó la
              visita presencial, es como se lo podemos entregar.
            </p>
          </li>
        </ul>
      </div>
    </ClarificationsWrapper>
  );
};

export default Clarifications;
