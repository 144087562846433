import styled from "styled-components";

import {
  scarpaGray500,
  scarpaGray800,
  spanishViridian600,
  size,
} from "@habitech/shared";

export const TermsWrapper = styled.div`
  .content {
    &-header {
      &-title {
        font: 600 16px Montserrat;
      }
      &-intro {
        font: 400 14px Montserrat;
      }
    }
    &-main {
      font: 400 14px Montserrat;
      line-height: 24px;
      strong {
        font: 600 14px Montserrat;
      }
    }
    &-terms {
      font: 400 14px Montserrat;
      line-height: 24px;
      .content-term {
        &-list {
          list-style-type: disc;
          li {
            list-style-type: disc;
          }
        }
      }
    }
    &-policies {
      color: ${spanishViridian600};
      margin: 0 0 24px;
      font: 400 12px Montserrat;
      line-height: 20px;
    }
    &-conclusion {
      color: ${spanishViridian600};
      margin: 0 0 24px;
      font: 400 14px Montserrat;
      line-height: 20px;
    }
  }
  .footer {
    font: 400 16px Montserrat;
    margin-bottom: 26px;
    &-properties {
      .footer-property {
        margin-bottom: 16px;
        &-label {
          font: 400 12px "Montserrat";
          color: ${scarpaGray500};
          margin: 0;
        }
        &-value {
          font: 400 16px "Montserrat";
          color: ${scarpaGray800};
          margin: 0;
        }
      }
    }
  }
  @media screen and (min-width: ${size.tablet.min}) {
    .content {
      font: 400 14px Montserrat;
    }
    .content-header {
    }
    .content-header-title {
      font: 600 16px Montserrat;
      margin: 44px 0 12px;
    }
    .content-header-intro p {
      margin: 0 0 12px;
    }
    .content-main {
      margin: 24px 0;
      color: ${scarpaGray800};
      line-height: 24px;
    }
    .content-main strong {
      font: 700 14px Montserrat;
    }
    .content-terms {
      padding-left: 18px;
    }
    .content-term {
      margin: 0 0 12px;
    }
    .content-term:last-child {
      margin: 0 0 24px;
    }

    .content-conclusion {
      font: 400 14px Montserrat;
      color: ${spanishViridian600};
      margin: 0 0 24px;
    }
    .footer {
      font: 400 16px Montserrat;
    }
    .footer-properties {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 24px;
      margin: 0 0 32px;
    }

    .footer-property-label {
      color: ${scarpaGray500};
      font: 400 12px Montserrat;
      margin: 0 0 6px;
    }
    .footer-property-value {
      color: ${scarpaGray800};
      font: 400 16px Montserrat;
      margin: 0;
    }
  }
`;
