import React from "react";
import { WompiPaymentSuccessWrapper } from "./styles";

import heroIcon from "../../assets/hero-habi-wompi.svg";
import circleHabiLogoIcon from "../../assets/circle-habi-logo.svg";

import Label from "components/Label/Label";
import { Hr } from "components/generic";

const WompiPaymentSuccess = () => {
  return (
    <WompiPaymentSuccessWrapper>
      <div className="main-container">
        <div className="hero">
          <img src={heroIcon} alt="hero" />
        </div>
        <section className="header-container">
          <img src={circleHabiLogoIcon} alt="circle-habi-Logo" />
          <Label
            as="h2"
            fontFamily="montserrat"
            fontSize="s-10"
            fontWeight="w-600"
            color="scarpaGray500"
            classLabel="header-container-title"
          >
            Transacción exitosa
          </Label>
          <Label
            as="h3"
            fontFamily="montserrat"
            fontSize="s-24"
            fontWeight="w-600"
            color="scarpaGray800"
            classLabel="header-container-content"
          >
            ¡Felicidades! La transacción con Wompi ha sido exitosa.
          </Label>
          <Hr />
        </section>
      </div>
    </WompiPaymentSuccessWrapper>
  );
};

WompiPaymentSuccess.defaultProps = {};

WompiPaymentSuccess.propTypes = {};

export default WompiPaymentSuccess;
