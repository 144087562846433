import { scarpaGray800, size } from "@habitech/shared";
import styled from "styled-components";

export const HeaderWrapper = styled.header`
  font: 400 10px Montserrat;
  .header-logo-image {
    width: 44px;
    height: 42px;
  }
  &-logo {
    &-text {
      font: 400 10px Montserrat;
      line-height: 14px;
      display: flex;
      flex-direction: column;
      color: ${scarpaGray800};
      p {
        margin: 0;
      }
    }
  }
  .header-logo-text {
    font: 400 10px Montserrat;
    color: ${scarpaGray800};
  }
  .header-title {
    font: 600 16px Montserrat;
    color: ${scarpaGray800};
  }
  &-title {
    display: none;
  }
  @media screen and (min-width: ${size.tablet.min}) {
    .header-logo {
      display: flex;
      align-items: center;
    }
    .header-logo-image {
      width: 44px;
      height: 42px;
      margin-right: 56px;
    }
    .header-logo-text {
      font: 400 10px Montserrat;
      color: ${scarpaGray800};
    }
    .header-title {
      font: 600 16px Montserrat;
      color: ${scarpaGray800};
    }
  }
`;
