import styled from "styled-components";

export const ClarificationsWrapper = styled.div`
  margin-bottom: 24px;
  .title {
    font: 400 16px Montserrat SemiBold;
    margin-bottom: 20px;
  }
  .points-container {
    .clarifications-list {
      list-style: num;
      .clarification-item {
        font: 400 14px Montserrat;
        color: #252129;
      }
    }
  }
`;
