import { DropdownButton } from "@habitech/call-to-action";
import { Checkbox } from "@habitech/selectors";
import { FC } from "react";
import { FormWrapper } from "../AcceptTermsAndConditionsMx/styles";

// Components

// Styles

import { AcceptTermsAndConditionsCoWrapper } from "./styles";

// helpers

interface AcceptTermsAndConditionsCoProps {
  handleChange: any;
}

const AcceptTermsAndConditionsCo: FC<AcceptTermsAndConditionsCoProps> = ({
  handleChange,
}) => {
  const onChangeValueField = (name, value) => {
    handleChange((prev) => {
      const prevCopy = { ...prev };
      prevCopy[name] = value;
      return prevCopy;
    });
  };

  return (
    <FormWrapper>
      <fieldset className="footer_form_terms">
        <div className="form-item">
          <Checkbox
            dataId="test-data-id"
            id="test-id"
            name="terms"
            onChange={(e, isChecked) => {
              if (isChecked) {
                return onChangeValueField("terms", e);
              }
              return onChangeValueField("terms", "");
            }}
            type="default"
            value="accept"
          />
          <label htmlFor="terms">
            <span className="footer_form_label">
              Acepto los <strong>Términos y Condiciones</strong> descritos en
              este contrato
            </span>
          </label>
        </div>
      </fieldset>
    </FormWrapper>
  );
};

export default AcceptTermsAndConditionsCo;
