import { FC } from "react";

// Styles
import { AuthorizationParagraphWrapper } from "../AuthorizationParagraphWrapper";

// helpers

interface AuthorizationParagraphCoProps {}

const AuthorizationParagraphCo: FC<AuthorizationParagraphCoProps> = () => {
  return (
    <AuthorizationParagraphWrapper className="content-policies">
      <p className="paragraph">
        "AUTORIZO a Inversiones MCN SAS, y a las entidades con quienes
        intermedie en el proceso de validación y aprobación, para obtener y
        consultar información que se refiere a mi (nuestro) comportamiento
        crediticio, financiero, comercial y de servicios reportada en cualquier
        CENTRAL DEL RIESGOS (como Datacrédito o TransUnion, sin limitarse a
        estas), o BASE DE DATOS, tales como CENTRAL DE INFORMACIÓN DE ENTIDADES
        FINANCIERAS DE COLOMBIA o PROCRÉDITO o a quien represente sus derechos u
        opere dichas entidades. Por lo tanto, dentro de los términos de Ley y
        sus decretos reglamentarios, mediante la presente, autorizo a Habi para
        que consulte las listas públicas establecidas para el control de LA/FT,
        así como las centrales de información y/o bases de datos públicas,
        correspondiente a la persona natural o jurídica que represento, a sus
        representantes legales, revisores fiscales, miembros de Junta Directiva
        y Socios. Además, autorizo a INVERSIONES MCN S.A.S para que realice el
        tratamiento de mis datos personales, actividad que incluye la
        recolección, almacenamiento, actualización, uso, circulación,
        transmisión, transferencia y supresión, de acuerdo con los fines
        expuestos en Política de Tratamiento de Datos Personales, los cuales se
        encuentran en la página{" "}
        <a className="content-policies-link" href="https://habi.co/">
          www.habi.co.
        </a>
        "
      </p>
    </AuthorizationParagraphWrapper>
  );
};

export default AuthorizationParagraphCo;
