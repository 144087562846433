import React from "react";
import { PaymentProcessWrapper } from "./styles";

// Components
import { Hr } from "components/generic";
import Loader from "components/loader";
import Button from "@habitech/ui-button";
import Label from "components/Label/Label";

//Services
import { useDecrypt } from "services/home";

// Icons
import heroIcon from "../../../../assets/hero-habi-wompi.svg";
import circleHabiLogoIcon from "../../../../assets/circle-habi-logo.svg";
import moneyCircleIcon from "../../../../assets/icon-money-circle.svg";
import Error from "pages/Error";

// Constants

const PaymentProcess = ({ token }) => {
  const { data, isLoading, isError } = useDecrypt({ token });

  if (isLoading) return <Loader scale={1} />;
  if (isError) return <Error />;
  const { currency, reference, publicKey, amountInCents, signatureIntegrity,nid, } =
    data?.wompi_data;
  const {
    nombre_de_edificio_conjunto,
    direccion,
    numero_de_apartamento,
    precio_pago,
    precio_inmueble,
  } = data?.property_data;
  const {
    telefono_del_cliente,
    correo_electronico_del_cliente,
    nombre_completo_del_cliente,
    numero_de_identificacion_cliente,
  } = data?.client_data;

  return (
    <PaymentProcessWrapper>
      <div className="main-container">
        <div className="hero">
          <img src={heroIcon} alt="hero" />
        </div>
        <section className="header-container">
          <img src={circleHabiLogoIcon} alt="circle-habi-Logo" />
          <Label
            as="h2"
            fontFamily="montserrat"
            fontSize="s-10"
            fontWeight="w-600"
            color="scarpaGray500"
            classLabel="header-container-title"
          >
            separación de inmueble habi
          </Label>
          <Label
            as="h3"
            fontFamily="montserrat"
            fontSize="s-24"
            fontWeight="w-600"
            color="scarpaGray800"
            classLabel="header-container-content"
          >
            Hola {nombre_completo_del_cliente}, aquí podrás realizar el pago
            para la separación de tu vivienda con habi
          </Label>
        </section>
        <section className="property-information-container">
          <Label
            as="h2"
            fontFamily="montserrat"
            fontSize="s-10"
            fontWeight="w-600"
            color="scarpaGray500"
            classLabel="property-information-title"
          >
            INMUEBLE
          </Label>
          <Label
            as="h3"
            fontFamily="montserrat"
            fontSize="s-16"
            fontWeight="w-600"
            color="scarpaGray800"
            classLabel="property-information-name"
          >
            {`${nombre_de_edificio_conjunto}, apt ${numero_de_apartamento}`}
          </Label>
          <Label
            as="h3"
            fontFamily="montserrat"
            fontSize="s-14"
            fontWeight="w-400"
            color="spanishViridian600"
            classLabel="property-information-location"
          >
            {`${direccion}`}
          </Label>
          <Hr />
          <div className="values-container">
            <div className="value flex justify-space-between gap-12">
              <Label
                as="span"
                fontFamily="montserrat"
                fontSize="s-14"
                fontWeight="w-400"
                color="spanishViridian600"
              >
                Valor total del inmueble:
              </Label>
              <Label
                as="span"
                fontFamily="montserrat"
                fontSize="s-16"
                fontWeight="w-600"
                color="spanishViridian600"
              >
                $ {precio_inmueble}
              </Label>
            </div>
            <div className="advance flex justify-space-between gap-12">
              <Label
                as="span"
                fontFamily="montserrat"
                fontSize="s-16"
                fontWeight="w-600"
                color="scarpaGray800"
              >
                Anticipo a pagar:
              </Label>
              <Label
                as="span"
                fontFamily="montserrat"
                fontSize="s-16"
                fontWeight="w-600"
                color="scarpaGray800"
              >
                $ {precio_pago}
              </Label>
            </div>
          </div>
          <form action="https://checkout.wompi.co/p/" method="GET">
            <Button
              dataId="login-button"
              type="submit"
              className="footer_form_button"
              size="large"
              iconRight={<img src={moneyCircleIcon} alt={"currency-icon"} />}
            >
              Proceder al pago
            </Button>
            <input type="hidden" name="currency" value={currency} />
            <input type="hidden" name="reference" value={reference} />
            <input type="hidden" name="public-key" value={publicKey} />
            {/* <input type="hidden" name="shipping-address:city" value={ciudad} /> */}
            <input type="hidden" name="amount-in-cents" value={amountInCents} />
            <input
              type="hidden"
              name="customer-data:phone-number"
              value={telefono_del_cliente}
            />
            <input
              type="hidden"
              name="customer-data:email"
              value={correo_electronico_del_cliente}
            />
            <input
              type="hidden"
              name="customer-data:full-name"
              value={nombre_completo_del_cliente}
            />
            <input
              type="hidden"
              name="signature:integrity"
              value={signatureIntegrity}
            />
            <input
              type="hidden"
              name="data-customer-data:legal-id"
              value={numero_de_identificacion_cliente}
            />
            <input
              type="hidden"
              name="data-customer-data:nid"
              value={nid}
            />
          </form>
        </section>
      </div>
    </PaymentProcessWrapper>
  );
};

export default PaymentProcess;
