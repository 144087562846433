import styled from "styled-components";

export const ConfirmDataWrapper = styled.div`
  padding: 15px;
  .question {
    text-align: justify;
    margin-bottom: 24px;
  }
  .info-container {
    margin-bottom: 24px;
    form {
      button {
        float: right;
      }
    }
  }
  .actions-container {
    display: flex;
    gap: 24px;
    text-align: right;
    justify-content: right;
  }
`;
