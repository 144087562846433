type typeValidationsType = "string" | "number" | "boolean" | "date";

export type typeType =
  | "text"
  | "password"
  | "number"
  | "email"
  | "checkbox"
  | "radio";

type settingsValidationsStringType =
  | "required"
  | "min"
  | "max"
  | "matches"
  | "email"
  | "min"
  | "url";

type settingsValidationsNumberType =
  | "min"
  | "max"
  | "lessThan"
  | "moreThan"
  | "positive"
  | "negative"
  | "integer"
  | "boolean";

type settingsValidationsDateType = "min" | "max";

type settingsValidationType = {
  type:
    | settingsValidationsStringType
    | settingsValidationsNumberType
    | settingsValidationsDateType;
  message?: string;
  limit?: number;
  regex?: RegExp;
};

type validationType = {
  type?: typeValidationsType;
  settings?: settingsValidationType[];
};

export enum fieldTypeEnum {
  text = "text",
  select = "select",
}

export type optionsType = {
  label: string;
  value: any;
};

export type formConfigType = {
  name: string;
  label: string;
  validation?: validationType;
  value: any;
  type: typeType;
  fieldType: fieldTypeEnum;
  placeholder?: any;
  options?: optionsType[];
};

// This is for default values in useForm
export type defaultValuesType = {
  [key: string]: any;
};

// This part is to define data provides from truora

type escrituracionType = {
  valor: string;
  tipo: string;
  banco: string;
  metodo: string;
};

export interface IDataTruora {
  card_id: number;
  telefono_del_cliente: string;
  correo_electronico_del_cliente: string;
  numero_de_identificacion_cliente: string;
  estado_civil_del_cliente: string;
  nombre_completo_del_cliente: string;
  ciudad_de_residencia_del_cliente: string;
  nombre_del_cliente: string;
  idx: string;
  direccion: string;
  numero_de_apartamento: string;
  numero_de_clientes: string;
  nombre_de_edificio_conjunto: string;
  ciudad: string;
  fecha_de_pago_de_la_separacion: string;
  valor_de_las_arras: string;
  valor_del_saldo_del_precio: string;
  numero_de_pagos: string;
  esquema_de_financiacion: string;
  interior: string;
  precio_de_transaccion_acordado: string;
  fecha_tentativa_de_firma_de_promesa: string;
  fecha_tentativa_de_firma_de_escritura: string;
  valor_de_la_separacion: string;
  fecha_de_pago_de_las_arras: string;
  metodo_de_pago_de_las_arras: string;
  metodo_de_pago_de_la_separacion: string;
  escrituracion: escrituracionType[];
}

export interface IConfig extends formConfigType {}
