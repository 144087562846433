import API from "../Api";
import { encryptPath, postUpdateTruoraData, truoraPath } from "services/paths";

export const encodedData = (data) => {
  const ulr =
    process.env.REACT_APP_API_BASE_URL +
    process.env.REACT_APP_API_BASE_PATH +
    truoraPath +
    encryptPath;
  return API.post(ulr, data);
};

export const updateTruoraData = (data: any) => {
  const ulr =
    process.env.REACT_APP_API_BASE_URL +
    process.env.REACT_APP_API_BASE_PATH +
    postUpdateTruoraData;
  return API.post(ulr, data);
};
