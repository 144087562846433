import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AffidavitState, FormData } from "./models/affidavitSlice.model";
import { formState } from "./defaultState";

const initialState: AffidavitState = {
  stage: "central_risk",
  progress: 30,
  formState,
  infoUser: {},
};

export const affidavitSlice = createSlice({
  name: "affidavit",
  initialState,
  reducers: {
    changeStage: (state, action: PayloadAction<AffidavitState>) => {
      state.stage = action.payload.stage;
      state.progress = action.payload.progress;
      state.infoUser = action.payload.infoUser;
    },
    changeForm: (state, action: PayloadAction<FormData>) => {
      state.formState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeStage, changeForm } = affidavitSlice.actions;

export default affidavitSlice.reducer;
