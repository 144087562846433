import { FEEDBACK_MESSAGES } from "constants/form";
import { fieldTypeEnum, IConfig } from "models";
import { optionsCivilStateCo, optionsTypeIdentificationCo } from "./constants";

export const formConfigCo: IConfig[] = [
  {
    name: "tipo_de_identificacion_del_cliente",
    label: "Tipo de identificación",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.select,
    placeholder: "Tipo de identificación",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
    options: optionsTypeIdentificationCo,
  },
  {
    name: "numero_de_identificacion_cliente",
    label: "Número de identificación del cliente",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Número de identificación del cliente",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "ciudad_de_residencia_del_cliente",
    label: "Ciudad de residencia del cliente",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Ciudad de residencia del cliente",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "direccion_de_residencia_para_notificacion",
    label: "Dirección de residencia para notificación",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Dirección de residencia para notificación",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "estado_civil_del_cliente",
    label: "Estado civil",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.select,
    placeholder: "Estado civil ",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
    options: optionsCivilStateCo,
  },
  {
    name: "correo_electronico_del_cliente",
    label: "Correo",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Correo",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
];
