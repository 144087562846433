import { FC } from "react";

// Components
import logo from "../../../../../assets/logo.png";

// Styles

import { HeaderWrapper } from "../HeaderWrapper";

// helpers

const HeaderCo = () => {
  return (
    <HeaderWrapper>
      <div className="header-logo">
        <img src={logo} className="header-logo-image" alt="logo" />
        <h5 className="header-logo-text">
          <p>Inversiones MCN SAS</p>
          <p>NIT 901.303.824-1</p>
          <p>Calle 93b #16-47</p>
        </h5>
      </div>
      <div className="header-title">
        <h4>Solicitud de Compra</h4>
      </div>
    </HeaderWrapper>
  );
};

export default HeaderCo;
