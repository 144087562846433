import React from "react";
import { useSearchParams } from "react-router-dom";

// Components
import NotFound from "pages/NotFound";
import PaymentProcess from "./components/PaymentProcess/PaymentProcess";

const PaymentGatewayWompi = () => {
  const [searchParams] = useSearchParams();

  const tokenUrl = searchParams.get("token");

  if (!tokenUrl) return <NotFound />;

  return <PaymentProcess token={tokenUrl} />;
};

export default PaymentGatewayWompi;
