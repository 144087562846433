import "./styles.css";
import routes from "utils/routes";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "store/store";
import { Suspense, useEffect } from "react";
import { Skeletor } from "@habitech/shared";
import faviconMx from "assets/tuHabimx.svg";

function App() {
  useEffect(() => {
    try {
      const location = window.location.pathname;
      const routeMx = location
        .split("/")
        .find((item) => item === "declaracion-juramentada-mx");
      if (routeMx) {
        let link = document.querySelector("link[rel~='icon']");
        if (!link) {
          link = document.createElement("link");
          link.rel = "icon";
          document.getElementsByTagName("head")[0].appendChild(link);
        }
        link.href = faviconMx;
      }
    } catch (error) {
      console.error("🚀 error:", error);
    }
  }, []);

  return (
    <Provider store={store}>
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
            }}
          >
            <Skeletor width="90%" height="40px" quantity={2} />
          </div>
        }
      >
        <RouterProvider router={routes} />
      </Suspense>
    </Provider>
  );
}

export default App;
