import { FC, Fragment } from "react";

// Components
import { TextField } from "@habitech/keychain-atoms-inputs";

// Styles
import { DynamicFormWrapper } from "./styles";

// Model
import { DynamicFormProps } from "./model";
import { Controller } from "react-hook-form";
import { DropdownButton } from "@habitech/call-to-action";

// helpers

const DynamicForm: FC<DynamicFormProps> = ({
  formConfig,
  errors,
  setValue,
  control,
  numberOfColumns = 1,
}) => {
  const getComponentByFieldType = ({ name, ...props }) => {
    if (props.fieldType === "text") {
      return (
        <TextField
          name={name}
          label={props.label}
          type={props.type}
          defaultValue={props.value}
          dataId={name}
          placeHolder={props.placeholder}
          onChange={(e, value) => setValue(name, value)}
          error={!!errors[name]}
          errorMessage={errors[name]?.message || ""}
        />
      );
    }
    if (props.fieldType === "select") {
      return (
        <div className="select-container">
          <DropdownButton
            variant="white"
            label={props.label}
            key={props.name}
            dataId={props.name}
            disabled={props.disabled}
            value={props.value}
            defaultSelected={props.value}
            setValue={(e) => setValue(name, e)}
            listOptions={props.options}
            size="fluid"
          />
        </div>
      );
    }
  };

  return (
    <DynamicFormWrapper numberOfColumns={numberOfColumns}>
      {formConfig?.map(({ name, ...props }, index) => (
        <Fragment key={`${name}_${index}`}>
          <Controller
            name={name}
            control={control}
            render={() => {
              return <>{getComponentByFieldType({ name, ...props })}</>;
            }}
          />
        </Fragment>
      ))}
    </DynamicFormWrapper>
  );
};

export default DynamicForm;
