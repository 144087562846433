import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { decryptPathAffidavit, postAffidavitForm } from "services/paths";

interface Data {
  name: string;
}

const urlForm =
  process.env.REACT_APP_AFFIDAVIT_BASE_URL +
  process.env.REACT_APP_API_BASE_PATH;
const params = {
  country: "CO",
};

const apiKey = process.env.REACT_APP_AFFIDAVIT_API_KEY;

export const affidavitApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: urlForm }),
  endpoints: (builder) => ({
    getAffidavitData: builder.query({
      query: (arg) => {
        const { token } = arg;
        return {
          url: decryptPathAffidavit,
          params: { token, country: params.country },
          headers: { "x-api-key": apiKey },
        };
      },
      transformResponse: (response: { data: Data }, meta, arg) => {
        return {
          name: "name",
          price_minimal_inmobi: "100000000",
          ...response,
        };
      },
    }),
    addNewFormPost: builder.mutation({
      query: (payload) => ({
        url: postAffidavitForm,
        method: "POST",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          "x-api-key": apiKey,
        },
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useGetAffidavitDataQuery, useAddNewFormPostMutation } =
  affidavitApi;
