import { FC } from "react";

// Styles
import { AuthorizationParagraphWrapper } from "../AuthorizationParagraphWrapper";

// helpers

interface AuthorizationParagraphMxProps {}

const AuthorizationParagraphMx: FC<AuthorizationParagraphMxProps> = () => {
  return (
    <AuthorizationParagraphWrapper className="content-policies">
      <p className="paragraph">
        &quot;Autorizo expresamente a CORPORATIVO MCNEMEXICO, S. de R.L. de C.V.
        (“TuHabi”), para que lleve a cabo investigaciones sobre el
        comportamiento crediticio de mi persona, con Sociedades de Información
        Crediticia durante el tiempo que mantenga relación jurídica con TuHabi,
        por lo que, declaro que conozco la naturaleza y alcance de la
        información que se solicitará, del uso que TuHabi hará de los datos
        recabados y que ésta podrá realizar consultas periódicas del historial
        crediticio de mi persona.
      </p>
      <p className="paragraph">
        Así mismo, otorgo mi consentimiento de manera libre, expresa, voluntaria
        e informada a TuHabi, para recolectar, recaudar, almacenar, usar,
        circular, suprimir, procesar, compilar, intercambiar, dar tratamiento,
        actualizar, transmitir, transferir y disponer de mis datos personales.
        Estos datos podrán ser incorporados en distintas bases de datos físicas
        y/o digitales o en medios electrónicos de todo tipo con que cuenta
        TuHabi con el fin de comunicar información comercial, financiera o
        administrativa. El tratamiento de los datos suministrados por el
        presente medio se realizará por TuHabi, de forma directa o a través de
        terceros debidamente autorizados de conformidad al cumplimiento de los
        protocolos establecidos en la política de Protección de datos y en la
        Ley (según término se define más adelante).
      </p>
      <p className="paragraph">
        En cumplimiento con la Ley Federal de Protección de Datos Personales en
        Posesión de los Particulares (la “Ley”), ponemos a su disposición el
        presente aviso de privacidad integral (el “Aviso de Privacidad”), el
        cual podrá consultar en la página
        <a className="content-policies-link" href="https://habi.co/">
          www.tuhabi.mx
        </a>
        . "
      </p>
    </AuthorizationParagraphWrapper>
  );
};

export default AuthorizationParagraphMx;
