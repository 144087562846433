export const optionsSelect = [
  {
    label: "Si",
    value: "Si",
  },
  {
    label: "No",
    value: "No",
  },
  {
    label:
      "No aplica porque voy a comprar el inmueble a través de recursos propios",
    value:
      "No aplica porque voy a comprar el inmueble a través de recursos propios",
  },

  {
    label: "No aplica porque ya tengo un crédito aprobado con otro banco ",
    value: "No aplica porque ya tengo un crédito aprobado con otro banco",
  },
];
