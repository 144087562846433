import React from "react";
import notFound from "assets/notFound.png";
import "./styles.css";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="notFound">
      <Link
        to={
          "/declaracion-juramentada-mx/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtaW5pbWFsX3ByaWNlIjoiMTQsMDAwLDAwMC4wMCIsImlkX2NsaWVudCI6MywiY2FyZF9pZCI6NjYxNzg5ODQ2LCJmdWxsbmFtZV9jbGllbnQiOiJZZXNpZCJ9.-0gfQ9Vk9ZJsm9Rwsqs27rDXcwIYmJEgbtrcaly-A7M"
        }
      >
        test
      </Link>
      <div className="notFound_picture">
        <img className="notFound_picture_image" src={notFound} alt="error" />
      </div>
      <div className="notFound_content">
        <h1 className="notFound_content_title">
          Jummm... No encontramos la página solicitada
        </h1>
        <p className="notFound_content_message">
          Es probable que falte un caracter en la url{" "}
          <strong>{window.location.href}</strong>
        </p>
      </div>
    </div>
  );
};

export default NotFound;
