export const valuesFormCo = {
  terms: "",
};

export const valuesFormMx = {
  el_cliente_esta_interesado_en_comprar_el_inmueble: "",
  el_cliente_conocio_el_inmueble: "",
  al_cliente_le_ofrecieron_banorte: "",
  al_cliente_le_ofrecieron_habicredit: "",
  terms: "",
};
