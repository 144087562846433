const CEDULA_DE_CIUDADANIA = "Cédula de Ciudadanía";
const CEDULA_DE_EXTRANGERIA = "Cédula de Extranjería";
const PASAPORTE = "Pasaporte";
const CASADO_SOCIEDAD_CONYUGAL = "Casado con Sociedad Conyugal";
const CASADO_SIN_SOCIEDAD_CONYUGAL = "Casado sin Sociedad Conyugal";
const SOLTERO_SIN_UNION_MARITAL = "Soltero sin unión marital de hecho";
const SOLTERO_CON_UNION_MARITAL_DE_HECHO = "Soltero con unión marital de hecho";
const CASADO = "Casado";
const SOLTERO = "Soltero";
const NIT = "Nit";
const CREDENCIAL_PARA_VOTAR = "Credencial para votar (INE)";

const FORMA_MIGRATORIA = "Forma migratoria";

const SELECCIONE = "Seleccione";

const PROVINCES = {
  AGUASCALIENTES: "Aguascalientes",
  BAJA_CALIFORNIA: "Baja California",
  BAJA_CALIFORNIA_SUR: "Baja California Sur",
  CAMPECHE: "Campeche",
  CHIAPAS: "Chiapas",
  CHIHUAHUA: "Chihuahua",
  CIUDAD_DE_MÉXICO: "Ciudad de México",
  COAHUILA: "Coahuila",
  COLIMA: "Colima",
  DURANGO: "Durango",
  GUANAJUATO: "Guanajuato",
  GUERRERO: "Guerrero",
  HIDALGO: "Hidalgo",
  JALISCO: "Jalisco",
  MÉXICO: "México",
  MICHOACÁN: "Michoacán",
  MORELOS: "Morelos",
  NAYARIT: "Nayarit",
  NUEVO_LEÓN: "Nuevo León",
  OAXACA: "Oaxaca",
  PUEBLA: "Puebla",
  QUERÉTARO: "Querétaro",
  QUINTANA_ROO: "Quintana Roo",
  SAN_LUIS_POTOSÍ: "San Luis Potosí",
  SINALOA: "Sinaloa",
  SONORA: "Sonora",
  TABASCO: "Tabasco",
  TAMAULIPAS: "Tamaulipas",
  TLAXCALA: "Tlaxcala",
  VERACRUZ: "Veracruz",
  YUCATÁN: "Yucatán",
  ZACATECAS: "Zacatecas",
};

const MUNICIPALITY = {
  ABALÁ: "Abalá",
  ACATIC: "Acatic",
  ACATLÁN_DE_JUÁREZ: "Acatlán de Juárez",
  ACOLMAN: "Acolman",
  AHUALULCO_DE_MERCADO: "Ahualulco de Mercado",
  ALMOLOYA_DE_JUÁREZ: "Almoloya de Juárez",
  ALMOLOYA_DEL_RÍO: "Almoloya del Río",
  ALVARADO: "Alvarado",
  AMACUECA: "Amacueca",
  AMATEPEC: "Amatepec",
  AMATITÁN: "Amatitán",
  AMECA: "Ameca",
  AMECAMECA: "Amecameca",
  APASEO_EL_GRANDE: "Apaseo el Grande",
  APODACA: "Apodaca",
  ARANDAS: "Arandas",
  ATEMAJAC_DE_BRIZUELA: "Atemajac de Brizuela",
  ATENCO: "Atenco",
  ATENGO: "Atengo",
  ATENGUILLO: "Atenguillo",
  ATIZAPÁN_DE_ZARAGOZA: "Atizapán de Zaragoza",
  ATLATLAHUCAN: "Atlatlahucan",
  ATLAUTLA: "Atlautla",
  ATOTONILCO_DE_TULA: "Atotonilco de Tula",
  ATOTONILCO_EL_ALTO: "Atotonilco el Alto",
  ATOYAC: "Atoyac",
  AUTLÁN_DE_NAVARRO: "Autlán de Navarro",
  AXAPUSCO: "Axapusco",
  AYALA: "Ayala",
  AYAPANGO: "Ayapango",
  AYOTLÁN: "Ayotlán",
  AYUTLA: "Ayutla",
  BACA: "Baca",
  BOCA_DEL_RÍO: "Boca del Río",
  BOLAÑOS: "Bolaños",
  CABO_CORRIENTES: "Cabo Corrientes",
  CADEREYTA_JIMÉNEZ: "Cadereyta Jiménez",
  CALIMAYA: "Calimaya",
  CALPULALPAN: "Calpulalpan",
  CAÑADAS_DE_OBREGÓN: "Cañadas de Obregón",
  CAPULHUAC: "Capulhuac",
  CASIMIRO_CASTILLO: "Casimiro Castillo",
  CHALCO: "Chalco",
  CHAPALA: "Chapala",
  CHAPULTEPEC: "Chapultepec",
  CHIAUTLA: "Chiautla",
  CHICOLOAPAN: "Chicoloapan",
  CHICONCUAC: "Chiconcuac",
  CHIMALHUACÁN: "Chimalhuacán",
  CHIMALTITÁN: "Chimaltitán",
  CHIQUILISTLÁN: "Chiquilistlán",
  CIÉNEGA_DE_FLORES: "Ciénega de Flores",
  CIHUATLÁN: "Cihuatlán",
  CIUDAD_DE_MÉXICO: "Ciudad de México",
  COACALCO_DE_BERRIOZÁBAL: "Coacalco de Berriozábal",
  COCOTITLÁN: "Cocotitlán",
  COCULA: "Cocula",
  COLÓN: "Colón",
  COLOTLÁN: "Colotlán",
  CONCEPCIÓN_DE_BUENOS_AIRES: "Concepción de Buenos Aires",
  CONKAL: "Conkal",
  CORREGIDORA: "Corregidora",
  COYOTEPEC: "Coyotepec",
  CUAUHTÉMOC: "Cuauhtémoc",
  CUAUTITLÁN: "Cuautitlán",
  CUAUTITLÁN_DE_GARCÍA_BARRAGÁN: "Cuautitlán de García Barragán",
  CUAUTITLÁN_IZCALLI: "Cuautitlán Izcalli",
  CUAUTLA: "Cuautla",
  CUERNAVACA: "Cuernavaca",
  CUQUÍO: "Cuquío",
  DEGOLLADO: "Degollado",
  ECATEPEC_DE_MORELOS: "Ecatepec de Morelos",
  ECATZINGO: "Ecatzingo",
  EJUTLA: "Ejutla",
  EL_ARENAL: "El Arenal",
  EL_CARMEN: "El Carmen",
  EL_GRULLO: "El Grullo",
  EL_LIMÓN: "El Limón",
  EL_MARQUÉS: "El Marqués",
  EL_ORO: "El Oro",
  EL_SALTO: "El Salto",
  EMILIANO_ZAPATA: "Emiliano Zapata",
  ENCARNACIÓN_DE_DÍAZ: "Encarnación de Díaz",
  EPAZOYUCAN: "Epazoyucan",
  ETZATLÁN: "Etzatlán",
  GARCÍA: "García",
  GENERAL_ESCOBEDO: "General Escobedo",
  GENERAL_ZUAZUA: "General Zuazua",
  GÓMEZ_FARÍAS: "Gómez Farías",
  GUACHINANGO: "Guachinango",
  GUADALAJARA: "Guadalajara",
  GUADALUPE: "Guadalupe",
  HOSTOTIPAQUILLO: "Hostotipaquillo",
  HUEHUETOCA: "Huehuetoca",
  HUEJÚCAR: "Huejúcar",
  HUEJUQUILLA_EL_ALTO: "Huejuquilla el Alto",
  HUEYPOXTLA: "Hueypoxtla",
  HUIMILPAN: "Huimilpan",
  HUITZILAC: "Huitzilac",
  HUIXQUILUCAN: "Huixquilucan",
  HUNUCMÁ: "Hunucmá",
  ISIDRO_FABELA: "Isidro Fabela",
  IXTAPALUCA: "Ixtapaluca",
  IXTLAHUACÁN_DE_LOS_MEMBRILLOS: "Ixtlahuacán de los Membrillos",
  IXTLAHUACÁN_DEL_RÍO: "Ixtlahuacán del Río",
  IZTACALCO: "Iztacalco",
  JALOSTOTITLÁN: "Jalostotitlán",
  JALTENCO: "Jaltenco",
  JAMAY: "Jamay",
  JESÚS_MARÍA: "Jesús María",
  JILOTLÁN_DE_LOS_DOLORES: "Jilotlán de los Dolores",
  JILOTZINGO: "Jilotzingo",
  JIQUIPILCO: "Jiquipilco",
  JIUTEPEC: "Jiutepec",
  JOCOTEPEC: "Jocotepec",
  JUANACATLÁN: "Juanacatlán",
  JUÁREZ: "Juárez",
  JUCHITEPEC: "Juchitepec",
  JUCHITLÁN: "Juchitlán",
  KANASÍN: "Kanasín",
  KANTUNIL: "Kantunil",
  KOPOMÁ: "Kopomá",
  LA_BARCA: "La Barca",
  LA_HUERTA: "La Huerta",
  LA_MANZANILLA_DE_LA_PAZ: "La Manzanilla de la Paz",
  LA_PAZ: "La Paz",
  LAGOS_DE_MORENO: "Lagos de Moreno",
  LERMA: "Lerma",
  LUVIANOS: "Luvianos",
  MAGDALENA: "Magdalena",
  MALINALCO: "Malinalco",
  MAMA: "Mama",
  MASCOTA: "Mascota",
  MAZAMITLA: "Mazamitla",
  MEDELLÍN_DE_BRAVO: "Medellín de Bravo",
  MELCHOR_OCAMPO: "Melchor Ocampo",
  MÉRIDA: "Mérida",
  METEPEC: "Metepec",
  MEXICALTZINGO: "Mexicaltzingo",
  MEXTICACÁN: "Mexticacán",
  MEZQUITIC: "Mezquitic",
  MINERAL_DE_LA_REFORMA: "Mineral de la Reforma",
  MINERAL_DEL_MONTE: "Mineral del Monte",
  MIXTLÁN: "Mixtlán",
  MONTERREY: "Monterrey",
  MORELOS: "Morelos",
  NAUCALPAN_DE_JUÁREZ: "Naucalpan de Juárez",
  NEXTLALPAN: "Nextlalpan",
  NEZAHUALCÓYOTL: "Nezahualcóyotl",
  NICOLÁS_ROMERO: "Nicolás Romero",
  NOPALTEPEC: "Nopaltepec",
  OCOTLÁN: "Ocotlán",
  OCOYOACAC: "Ocoyoacac",
  OCUILAN: "Ocuilan",
  OCUITUCO: "Ocuituco",
  OJUELOS_DE_JALISCO: "Ojuelos de Jalisco",
  OMITLÁN_DE_JUÁREZ: "Omitlán de Juárez",
  OTUMBA: "Otumba",
  OTZOLOTEPEC: "Otzolotepec",
  OZUMBA: "Ozumba",
  PACHUCA_DE_SOTO: "Pachuca de Soto",
  PAPALOTLA: "Papalotla",
  PESQUERÍA: "Pesquería",
  PIHUAMO: "Pihuamo",
  PONCITLÁN: "Poncitlán",
  PROGRESO: "Progreso",
  PUERTO_VALLARTA: "Puerto Vallarta",
  QUERÉTARO: "Querétaro",
  QUITUPAN: "Quitupan",
  RAYÓN: "Rayón",
  SALINAS_VICTORIA: "Salinas Victoria",
  SAN_AGUSTÍN_TLAXIACA: "San Agustín Tlaxiaca",
  SAN_ANTONIO_LA_ISLA: "San Antonio la Isla",
  SAN_CRISTÓBAL_DE_LA_BARRANCA: "San Cristóbal de la Barranca",
  SAN_DIEGO_DE_ALEJANDRÍA: "San Diego de Alejandría",
  SAN_GABRIEL: "San Gabriel",
  SAN_IGNACIO_CERRO_GORDO: "San Ignacio Cerro Gordo",
  SAN_JOSÉ_DEL_RINCÓN: "San José del Rincón",
  SAN_JOSÉ_GUADALUPE: "San José Guadalupe",
  SAN_JUAN_DE_LOS_LAGOS: "San Juan de los Lagos",
  SAN_JUANITO_DE_ESCOBEDO: "San Juanito de Escobedo",
  SAN_JULIÁN: "San Julián",
  SAN_MARCOS: "San Marcos",
  SAN_MARTÍN_DE_BOLAÑOS: "San Martín de Bolaños",
  SAN_MARTÍN_DE_LAS_PIRÁMIDES: "San Martín de las Pirámides",
  SAN_MARTÍN_HIDALGO: "San Martín Hidalgo",
  SAN_MATEO_ATENCO: "San Mateo Atenco",
  SAN_MIGUEL_EL_ALTO: "San Miguel el Alto",
  SAN_NICOLÁS_DE_LOS_GARZA: "San Nicolás de los Garza",
  SAN_PEDRO_GARZA_GARCÍA: "San Pedro Garza García",
  SAN_PEDRO_TLAQUEPAQUE: "San Pedro Tlaquepaque",
  SAN_SEBASTIÁN_DEL_OESTE: "San Sebastián del Oeste",
  SANTA_CATARINA: "Santa Catarina",
  SANTA_MARÍA_DE_LOS_ÁNGELES: "Santa María de los Ángeles",
  SANTA_MARÍA_DEL_ORO: "Santa María del Oro",
  SANTIAGO: "Santiago",
  SAYULA: "Sayula",
  SUDZAL: "Sudzal",
  SUMA: "Suma",
  TAHMEK: "Tahmek",
  TALA: "Tala",
  TALPA_DE_ALLENDE: "Talpa de Allende",
  TAMAZULA_DE_GORDIANO: "Tamazula de Gordiano",
  TAPALPA: "Tapalpa",
  TECALITLÁN: "Tecalitlán",
  TECÁMAC: "Tecámac",
  TECHALUTA_DE_MONTENEGRO: "Techaluta de Montenegro",
  TECOLOTLÁN: "Tecolotlán",
  TEMAMATLA: "Temamatla",
  TEMASCALAPA: "Temascalapa",
  TEMIXCO: "Temixco",
  TEMOAYA: "Temoaya",
  TENAMAXTLÁN: "Tenamaxtlán",
  TENANCINGO: "Tenancingo",
  TENANGO_DEL_AIRE: "Tenango del Aire",
  TENANGO_DEL_VALLE: "Tenango del Valle",
  TEOCALTICHE: "Teocaltiche",
  TEOCUITATLÁN_DE_CORONA: "Teocuitatlán de Corona",
  TEOLOYUCAN: "Teoloyucan",
  TEOTIHUACÁN: "Teotihuacán",
  TEPATITLÁN_DE_MORELOS: "Tepatitlán de Morelos",
  TEPEJI_DEL_RÍO_DE_OCAMPO: "Tepeji del Río de Ocampo",
  TEPETLAOXTOC: "Tepetlaoxtoc",
  TEPETLIXPA: "Tepetlixpa",
  TEPOTZOTLÁN: "Tepotzotlán",
  TEPOZTLÁN: "Tepoztlán",
  TEQUILA: "Tequila",
  TEQUIXQUIAC: "Tequixquiac",
  TETELA_DEL_VOLCÁN: "Tetela del Volcán",
  TEUCHITLÁN: "Teuchitlán",
  TEXCALYACAC: "Texcalyacac",
  TEXCOCO: "Texcoco",
  TEZOYUCA: "Tezoyuca",
  TIANGUISTENCO: "Tianguistenco",
  TIXPÉHUAL: "Tixpéhual",
  TIZAPÁN_EL_ALTO: "Tizapán el Alto",
  TIZAYUCA: "Tizayuca",
  TLAHUAPAN: "Tlahuapan",
  TLAJOMULCO: "Tlajomulco",
  TLAJOMULCO_DE_ZÚÑIGA: "Tlajomulco de Zúñiga",
  TLALMANALCO: "Tlalmanalco",
  TLALNEPANTLA: "Tlalnepantla",
  TLALNEPANTLA_DE_BAZ: "Tlalnepantla de Baz",
  TLALTIZAPÁN_DE_ZAPATA: "Tlaltizapán de Zapata",
  TLAQUEPAQUE: "Tlaquepaque",
  TLAYACAPAN: "Tlayacapan",
  TOLCAYUCA: "Tolcayuca",
  TOLIMÁN: "Tolimán",
  TOLUCA: "Toluca",
  TOMATLÁN: "Tomatlán",
  TONALÁ: "Tonalá",
  TONANITLA: "Tonanitla",
  TONAYA: "Tonaya",
  TONILA: "Tonila",
  TOTATICHE: "Totatiche",
  TOTOLAPAN: "Totolapan",
  TOTOTLÁN: "Tototlán",
  TULTEPEC: "Tultepec",
  TULTITLÁN: "Tultitlán",
  TUXCACUESCO: "Tuxcacuesco",
  TUXCUECA: "Tuxcueca",
  TUXPAN: "Tuxpan",
  UNIÓN_DE_SAN_ANTONIO: "Unión de San Antonio",
  UNIÓN_DE_TULA: "Unión de Tula",
  VALLE_DE_CHALCO_SOLIDARIDAD: "Valle de Chalco Solidaridad",
  VALLE_DE_GUADALUPE: "Valle de Guadalupe",
  VALLE_DE_JUÁREZ: "Valle de Juárez",
  VERACRUZ: "Veracruz",
  VILLA_CORONA: "Villa Corona",
  VILLA_DEL_CARBÓN: "Villa del Carbón",
  VILLA_GUERRERO: "Villa Guerrero",
  VILLA_HIDALGO: "Villa Hidalgo",
  VILLA_PURIFICACIÓN: "Villa Purificación",
  XOCHITEPEC: "Xochitepec",
  XONACATLÁN: "Xonacatlán",
  YAHUALICA_DE_GONZÁLEZ_GALLO: "Yahualica de González Gallo",
  YAUTEPEC: "Yautepec",
  YECAPIXTLA: "Yecapixtla",
  ZACOALCO_DE_TORRES: "Zacoalco de Torres",
  ZACUALPAN: "Zacualpan",
  ZAPOPAN: "Zapopan",
  ZAPOTILTIC: "Zapotiltic",
  ZAPOTITLÁN_DE_VADILLO: "Zapotitlán de Vadillo",
  ZAPOTLÁN_DE_JUÁREZ: "Zapotlán de Juárez",
  ZAPOTLÁN_DEL_REY: "Zapotlán del Rey",
  ZAPOTLÁN_EL_GRANDE: "Zapotlán el Grande",
  ZAPOTLANEJO: "Zapotlanejo",
  ZEMPOALA: "Zempoala",
  ZINACANTEPEC: "Zinacantepec",
  ZUMPAHUACÁN: "Zumpahuacán",
  ZUMPANGO: "Zumpango",
};

export const optionsTypeIdentificationCo = [
  {
    label: SELECCIONE,
    value: "",
  },
  {
    label: CEDULA_DE_CIUDADANIA,
    value: CEDULA_DE_CIUDADANIA,
  },
  {
    label: CEDULA_DE_EXTRANGERIA,
    value: CEDULA_DE_EXTRANGERIA,
  },
  {
    label: PASAPORTE,
    value: PASAPORTE,
  },
  {
    label: NIT,
    value: NIT,
  },
];
export const optionsTypeIdentificationMx = [
  {
    label: SELECCIONE,
    value: "",
  },
  {
    label: PASAPORTE,
    value: PASAPORTE,
  },
  {
    label: CREDENCIAL_PARA_VOTAR,
    value: CREDENCIAL_PARA_VOTAR,
  },
  {
    label: FORMA_MIGRATORIA,
    value: FORMA_MIGRATORIA,
  },
];

export const optionsCivilStateCo = [
  {
    label: SELECCIONE,
    value: "",
  },
  {
    label: CASADO_SOCIEDAD_CONYUGAL,
    value: CASADO_SOCIEDAD_CONYUGAL,
  },
  {
    label: CASADO_SIN_SOCIEDAD_CONYUGAL,
    value: CASADO_SIN_SOCIEDAD_CONYUGAL,
  },
  {
    label: SOLTERO_SIN_UNION_MARITAL,
    value: SOLTERO_SIN_UNION_MARITAL,
  },
  {
    label: SOLTERO_CON_UNION_MARITAL_DE_HECHO,
    value: SOLTERO_CON_UNION_MARITAL_DE_HECHO,
  },
];

export const optionsCivilStateMx = [
  {
    label: SELECCIONE,
    value: "",
  },
  {
    label: CASADO,
    value: CASADO,
  },
  {
    label: SOLTERO,
    value: SOLTERO,
  },
];

export const OPTIONS_MINICIPIO = [
  {
    label: MUNICIPALITY.ABALÁ,
    value: MUNICIPALITY.ABALÁ,
  },
  {
    label: MUNICIPALITY.ACATIC,
    value: MUNICIPALITY.ACATIC,
  },
  {
    label: MUNICIPALITY.ACATLÁN_DE_JUÁREZ,
    value: MUNICIPALITY.ACATLÁN_DE_JUÁREZ,
  },
  {
    label: MUNICIPALITY.ACOLMAN,
    value: MUNICIPALITY.ACOLMAN,
  },
  {
    label: MUNICIPALITY.AHUALULCO_DE_MERCADO,
    value: MUNICIPALITY.AHUALULCO_DE_MERCADO,
  },
  {
    label: MUNICIPALITY.ALMOLOYA_DE_JUÁREZ,
    value: MUNICIPALITY.ALMOLOYA_DE_JUÁREZ,
  },
  {
    label: MUNICIPALITY.ALMOLOYA_DEL_RÍO,
    value: MUNICIPALITY.ALMOLOYA_DEL_RÍO,
  },
  {
    label: MUNICIPALITY.ALVARADO,
    value: MUNICIPALITY.ALVARADO,
  },
  {
    label: MUNICIPALITY.AMACUECA,
    value: MUNICIPALITY.AMACUECA,
  },
  {
    label: MUNICIPALITY.AMATEPEC,
    value: MUNICIPALITY.AMATEPEC,
  },
  {
    label: MUNICIPALITY.AMATITÁN,
    value: MUNICIPALITY.AMATITÁN,
  },
  {
    label: MUNICIPALITY.AMECA,
    value: MUNICIPALITY.AMECA,
  },
  {
    label: MUNICIPALITY.AMECAMECA,
    value: MUNICIPALITY.AMECAMECA,
  },
  {
    label: MUNICIPALITY.APASEO_EL_GRANDE,
    value: MUNICIPALITY.APASEO_EL_GRANDE,
  },
  {
    label: MUNICIPALITY.APODACA,
    value: MUNICIPALITY.APODACA,
  },
  {
    label: MUNICIPALITY.ARANDAS,
    value: MUNICIPALITY.ARANDAS,
  },
  {
    label: MUNICIPALITY.ATEMAJAC_DE_BRIZUELA,
    value: MUNICIPALITY.ATEMAJAC_DE_BRIZUELA,
  },
  {
    label: MUNICIPALITY.ATENCO,
    value: MUNICIPALITY.ATENCO,
  },
  {
    label: MUNICIPALITY.ATENGO,
    value: MUNICIPALITY.ATENGO,
  },
  {
    label: MUNICIPALITY.ATENGUILLO,
    value: MUNICIPALITY.ATENGUILLO,
  },
  {
    label: MUNICIPALITY.ATIZAPÁN_DE_ZARAGOZA,
    value: MUNICIPALITY.ATIZAPÁN_DE_ZARAGOZA,
  },
  {
    label: MUNICIPALITY.ATLATLAHUCAN,
    value: MUNICIPALITY.ATLATLAHUCAN,
  },
  {
    label: MUNICIPALITY.ATLAUTLA,
    value: MUNICIPALITY.ATLAUTLA,
  },
  {
    label: MUNICIPALITY.ATOTONILCO_DE_TULA,
    value: MUNICIPALITY.ATOTONILCO_DE_TULA,
  },
  {
    label: MUNICIPALITY.ATOTONILCO_EL_ALTO,
    value: MUNICIPALITY.ATOTONILCO_EL_ALTO,
  },
  {
    label: MUNICIPALITY.ATOYAC,
    value: MUNICIPALITY.ATOYAC,
  },
  {
    label: MUNICIPALITY.AUTLÁN_DE_NAVARRO,
    value: MUNICIPALITY.AUTLÁN_DE_NAVARRO,
  },
  {
    label: MUNICIPALITY.AXAPUSCO,
    value: MUNICIPALITY.AXAPUSCO,
  },
  {
    label: MUNICIPALITY.AYALA,
    value: MUNICIPALITY.AYALA,
  },
  {
    label: MUNICIPALITY.AYAPANGO,
    value: MUNICIPALITY.AYAPANGO,
  },
  {
    label: MUNICIPALITY.AYOTLÁN,
    value: MUNICIPALITY.AYOTLÁN,
  },
  {
    label: MUNICIPALITY.AYUTLA,
    value: MUNICIPALITY.AYUTLA,
  },
  {
    label: MUNICIPALITY.BACA,
    value: MUNICIPALITY.BACA,
  },
  {
    label: MUNICIPALITY.BOCA_DEL_RÍO,
    value: MUNICIPALITY.BOCA_DEL_RÍO,
  },
  {
    label: MUNICIPALITY.BOLAÑOS,
    value: MUNICIPALITY.BOLAÑOS,
  },
  {
    label: MUNICIPALITY.CABO_CORRIENTES,
    value: MUNICIPALITY.CABO_CORRIENTES,
  },
  {
    label: MUNICIPALITY.CADEREYTA_JIMÉNEZ,
    value: MUNICIPALITY.CADEREYTA_JIMÉNEZ,
  },
  {
    label: MUNICIPALITY.CALIMAYA,
    value: MUNICIPALITY.CALIMAYA,
  },
  {
    label: MUNICIPALITY.CALPULALPAN,
    value: MUNICIPALITY.CALPULALPAN,
  },
  {
    label: MUNICIPALITY.CAÑADAS_DE_OBREGÓN,
    value: MUNICIPALITY.CAÑADAS_DE_OBREGÓN,
  },
  {
    label: MUNICIPALITY.CAPULHUAC,
    value: MUNICIPALITY.CAPULHUAC,
  },
  {
    label: MUNICIPALITY.CASIMIRO_CASTILLO,
    value: MUNICIPALITY.CASIMIRO_CASTILLO,
  },
  {
    label: MUNICIPALITY.CHALCO,
    value: MUNICIPALITY.CHALCO,
  },
  {
    label: MUNICIPALITY.CHAPALA,
    value: MUNICIPALITY.CHAPALA,
  },
  {
    label: MUNICIPALITY.CHAPULTEPEC,
    value: MUNICIPALITY.CHAPULTEPEC,
  },
  {
    label: MUNICIPALITY.CHIAUTLA,
    value: MUNICIPALITY.CHIAUTLA,
  },
  {
    label: MUNICIPALITY.CHICOLOAPAN,
    value: MUNICIPALITY.CHICOLOAPAN,
  },
  {
    label: MUNICIPALITY.CHICONCUAC,
    value: MUNICIPALITY.CHICONCUAC,
  },
  {
    label: MUNICIPALITY.CHIMALHUACÁN,
    value: MUNICIPALITY.CHIMALHUACÁN,
  },
  {
    label: MUNICIPALITY.CHIMALTITÁN,
    value: MUNICIPALITY.CHIMALTITÁN,
  },
  {
    label: MUNICIPALITY.CHIQUILISTLÁN,
    value: MUNICIPALITY.CHIQUILISTLÁN,
  },
  {
    label: MUNICIPALITY.CIÉNEGA_DE_FLORES,
    value: MUNICIPALITY.CIÉNEGA_DE_FLORES,
  },
  {
    label: MUNICIPALITY.CIHUATLÁN,
    value: MUNICIPALITY.CIHUATLÁN,
  },
  {
    label: MUNICIPALITY.CIUDAD_DE_MÉXICO,
    value: MUNICIPALITY.CIUDAD_DE_MÉXICO,
  },
  {
    label: MUNICIPALITY.COACALCO_DE_BERRIOZÁBAL,
    value: MUNICIPALITY.COACALCO_DE_BERRIOZÁBAL,
  },
  {
    label: MUNICIPALITY.COCOTITLÁN,
    value: MUNICIPALITY.COCOTITLÁN,
  },
  {
    label: MUNICIPALITY.COCULA,
    value: MUNICIPALITY.COCULA,
  },
  {
    label: MUNICIPALITY.COLÓN,
    value: MUNICIPALITY.COLÓN,
  },
  {
    label: MUNICIPALITY.COLOTLÁN,
    value: MUNICIPALITY.COLOTLÁN,
  },
  {
    label: MUNICIPALITY.CONCEPCIÓN_DE_BUENOS_AIRES,
    value: MUNICIPALITY.CONCEPCIÓN_DE_BUENOS_AIRES,
  },
  {
    label: MUNICIPALITY.CONKAL,
    value: MUNICIPALITY.CONKAL,
  },
  {
    label: MUNICIPALITY.CORREGIDORA,
    value: MUNICIPALITY.CORREGIDORA,
  },
  {
    label: MUNICIPALITY.COYOTEPEC,
    value: MUNICIPALITY.COYOTEPEC,
  },
  {
    label: MUNICIPALITY.CUAUHTÉMOC,
    value: MUNICIPALITY.CUAUHTÉMOC,
  },
  {
    label: MUNICIPALITY.CUAUTITLÁN,
    value: MUNICIPALITY.CUAUTITLÁN,
  },
  {
    label: MUNICIPALITY.CUAUTITLÁN_DE_GARCÍA_BARRAGÁN,
    value: MUNICIPALITY.CUAUTITLÁN_DE_GARCÍA_BARRAGÁN,
  },
  {
    label: MUNICIPALITY.CUAUTITLÁN_IZCALLI,
    value: MUNICIPALITY.CUAUTITLÁN_IZCALLI,
  },
  {
    label: MUNICIPALITY.CUAUTLA,
    value: MUNICIPALITY.CUAUTLA,
  },
  {
    label: MUNICIPALITY.CUERNAVACA,
    value: MUNICIPALITY.CUERNAVACA,
  },
  {
    label: MUNICIPALITY.CUQUÍO,
    value: MUNICIPALITY.CUQUÍO,
  },
  {
    label: MUNICIPALITY.DEGOLLADO,
    value: MUNICIPALITY.DEGOLLADO,
  },
  {
    label: MUNICIPALITY.ECATEPEC_DE_MORELOS,
    value: MUNICIPALITY.ECATEPEC_DE_MORELOS,
  },
  {
    label: MUNICIPALITY.ECATZINGO,
    value: MUNICIPALITY.ECATZINGO,
  },
  {
    label: MUNICIPALITY.EJUTLA,
    value: MUNICIPALITY.EJUTLA,
  },
  {
    label: MUNICIPALITY.EL_ARENAL,
    value: MUNICIPALITY.EL_ARENAL,
  },
  {
    label: MUNICIPALITY.EL_CARMEN,
    value: MUNICIPALITY.EL_CARMEN,
  },
  {
    label: MUNICIPALITY.EL_GRULLO,
    value: MUNICIPALITY.EL_GRULLO,
  },
  {
    label: MUNICIPALITY.EL_LIMÓN,
    value: MUNICIPALITY.EL_LIMÓN,
  },
  {
    label: MUNICIPALITY.EL_MARQUÉS,
    value: MUNICIPALITY.EL_MARQUÉS,
  },
  {
    label: MUNICIPALITY.EL_ORO,
    value: MUNICIPALITY.EL_ORO,
  },
  {
    label: MUNICIPALITY.EL_SALTO,
    value: MUNICIPALITY.EL_SALTO,
  },
  {
    label: MUNICIPALITY.EMILIANO_ZAPATA,
    value: MUNICIPALITY.EMILIANO_ZAPATA,
  },
  {
    label: MUNICIPALITY.ENCARNACIÓN_DE_DÍAZ,
    value: MUNICIPALITY.ENCARNACIÓN_DE_DÍAZ,
  },
  {
    label: MUNICIPALITY.EPAZOYUCAN,
    value: MUNICIPALITY.EPAZOYUCAN,
  },
  {
    label: MUNICIPALITY.ETZATLÁN,
    value: MUNICIPALITY.ETZATLÁN,
  },
  {
    label: MUNICIPALITY.GARCÍA,
    value: MUNICIPALITY.GARCÍA,
  },
  {
    label: MUNICIPALITY.GENERAL_ESCOBEDO,
    value: MUNICIPALITY.GENERAL_ESCOBEDO,
  },
  {
    label: MUNICIPALITY.GENERAL_ZUAZUA,
    value: MUNICIPALITY.GENERAL_ZUAZUA,
  },
  {
    label: MUNICIPALITY.GÓMEZ_FARÍAS,
    value: MUNICIPALITY.GÓMEZ_FARÍAS,
  },
  {
    label: MUNICIPALITY.GUACHINANGO,
    value: MUNICIPALITY.GUACHINANGO,
  },
  {
    label: MUNICIPALITY.GUADALAJARA,
    value: MUNICIPALITY.GUADALAJARA,
  },
  {
    label: MUNICIPALITY.GUADALUPE,
    value: MUNICIPALITY.GUADALUPE,
  },
  {
    label: MUNICIPALITY.HOSTOTIPAQUILLO,
    value: MUNICIPALITY.HOSTOTIPAQUILLO,
  },
  {
    label: MUNICIPALITY.HUEHUETOCA,
    value: MUNICIPALITY.HUEHUETOCA,
  },
  {
    label: MUNICIPALITY.HUEJÚCAR,
    value: MUNICIPALITY.HUEJÚCAR,
  },
  {
    label: MUNICIPALITY.HUEJUQUILLA_EL_ALTO,
    value: MUNICIPALITY.HUEJUQUILLA_EL_ALTO,
  },
  {
    label: MUNICIPALITY.HUEYPOXTLA,
    value: MUNICIPALITY.HUEYPOXTLA,
  },
  {
    label: MUNICIPALITY.HUIMILPAN,
    value: MUNICIPALITY.HUIMILPAN,
  },
  {
    label: MUNICIPALITY.HUITZILAC,
    value: MUNICIPALITY.HUITZILAC,
  },
  {
    label: MUNICIPALITY.HUIXQUILUCAN,
    value: MUNICIPALITY.HUIXQUILUCAN,
  },
  {
    label: MUNICIPALITY.HUNUCMÁ,
    value: MUNICIPALITY.HUNUCMÁ,
  },
  {
    label: MUNICIPALITY.ISIDRO_FABELA,
    value: MUNICIPALITY.ISIDRO_FABELA,
  },
  {
    label: MUNICIPALITY.IXTAPALUCA,
    value: MUNICIPALITY.IXTAPALUCA,
  },
  {
    label: MUNICIPALITY.IXTLAHUACÁN_DE_LOS_MEMBRILLOS,
    value: MUNICIPALITY.IXTLAHUACÁN_DE_LOS_MEMBRILLOS,
  },
  {
    label: MUNICIPALITY.IXTLAHUACÁN_DEL_RÍO,
    value: MUNICIPALITY.IXTLAHUACÁN_DEL_RÍO,
  },
  {
    label: MUNICIPALITY.IZTACALCO,
    value: MUNICIPALITY.IZTACALCO,
  },
  {
    label: MUNICIPALITY.JALOSTOTITLÁN,
    value: MUNICIPALITY.JALOSTOTITLÁN,
  },
  {
    label: MUNICIPALITY.JALTENCO,
    value: MUNICIPALITY.JALTENCO,
  },
  {
    label: MUNICIPALITY.JAMAY,
    value: MUNICIPALITY.JAMAY,
  },
  {
    label: MUNICIPALITY.JESÚS_MARÍA,
    value: MUNICIPALITY.JESÚS_MARÍA,
  },
  {
    label: MUNICIPALITY.JILOTLÁN_DE_LOS_DOLORES,
    value: MUNICIPALITY.JILOTLÁN_DE_LOS_DOLORES,
  },
  {
    label: MUNICIPALITY.JILOTZINGO,
    value: MUNICIPALITY.JILOTZINGO,
  },
  {
    label: MUNICIPALITY.JIQUIPILCO,
    value: MUNICIPALITY.JIQUIPILCO,
  },
  {
    label: MUNICIPALITY.JIUTEPEC,
    value: MUNICIPALITY.JIUTEPEC,
  },
  {
    label: MUNICIPALITY.JOCOTEPEC,
    value: MUNICIPALITY.JOCOTEPEC,
  },
  {
    label: MUNICIPALITY.JUANACATLÁN,
    value: MUNICIPALITY.JUANACATLÁN,
  },
  {
    label: MUNICIPALITY.JUÁREZ,
    value: MUNICIPALITY.JUÁREZ,
  },
  {
    label: MUNICIPALITY.JUCHITEPEC,
    value: MUNICIPALITY.JUCHITEPEC,
  },
  {
    label: MUNICIPALITY.JUCHITLÁN,
    value: MUNICIPALITY.JUCHITLÁN,
  },
  {
    label: MUNICIPALITY.KANASÍN,
    value: MUNICIPALITY.KANASÍN,
  },
  {
    label: MUNICIPALITY.KANTUNIL,
    value: MUNICIPALITY.KANTUNIL,
  },
  {
    label: MUNICIPALITY.KOPOMÁ,
    value: MUNICIPALITY.KOPOMÁ,
  },
  {
    label: MUNICIPALITY.LA_BARCA,
    value: MUNICIPALITY.LA_BARCA,
  },
  {
    label: MUNICIPALITY.LA_HUERTA,
    value: MUNICIPALITY.LA_HUERTA,
  },
  {
    label: MUNICIPALITY.LA_MANZANILLA_DE_LA_PAZ,
    value: MUNICIPALITY.LA_MANZANILLA_DE_LA_PAZ,
  },
  {
    label: MUNICIPALITY.LA_PAZ,
    value: MUNICIPALITY.LA_PAZ,
  },
  {
    label: MUNICIPALITY.LAGOS_DE_MORENO,
    value: MUNICIPALITY.LAGOS_DE_MORENO,
  },
  {
    label: MUNICIPALITY.LERMA,
    value: MUNICIPALITY.LERMA,
  },
  {
    label: MUNICIPALITY.LUVIANOS,
    value: MUNICIPALITY.LUVIANOS,
  },
  {
    label: MUNICIPALITY.MAGDALENA,
    value: MUNICIPALITY.MAGDALENA,
  },
  {
    label: MUNICIPALITY.MALINALCO,
    value: MUNICIPALITY.MALINALCO,
  },
  {
    label: MUNICIPALITY.MAMA,
    value: MUNICIPALITY.MAMA,
  },
  {
    label: MUNICIPALITY.MASCOTA,
    value: MUNICIPALITY.MASCOTA,
  },
  {
    label: MUNICIPALITY.MAZAMITLA,
    value: MUNICIPALITY.MAZAMITLA,
  },
  {
    label: MUNICIPALITY.MEDELLÍN_DE_BRAVO,
    value: MUNICIPALITY.MEDELLÍN_DE_BRAVO,
  },
  {
    label: MUNICIPALITY.MELCHOR_OCAMPO,
    value: MUNICIPALITY.MELCHOR_OCAMPO,
  },
  {
    label: MUNICIPALITY.MÉRIDA,
    value: MUNICIPALITY.MÉRIDA,
  },
  {
    label: MUNICIPALITY.METEPEC,
    value: MUNICIPALITY.METEPEC,
  },
  {
    label: MUNICIPALITY.MEXICALTZINGO,
    value: MUNICIPALITY.MEXICALTZINGO,
  },
  {
    label: MUNICIPALITY.MEXTICACÁN,
    value: MUNICIPALITY.MEXTICACÁN,
  },
  {
    label: MUNICIPALITY.MEZQUITIC,
    value: MUNICIPALITY.MEZQUITIC,
  },
  {
    label: MUNICIPALITY.MINERAL_DE_LA_REFORMA,
    value: MUNICIPALITY.MINERAL_DE_LA_REFORMA,
  },
  {
    label: MUNICIPALITY.MINERAL_DEL_MONTE,
    value: MUNICIPALITY.MINERAL_DEL_MONTE,
  },
  {
    label: MUNICIPALITY.MIXTLÁN,
    value: MUNICIPALITY.MIXTLÁN,
  },
  {
    label: MUNICIPALITY.MONTERREY,
    value: MUNICIPALITY.MONTERREY,
  },
  {
    label: MUNICIPALITY.MORELOS,
    value: MUNICIPALITY.MORELOS,
  },
  {
    label: MUNICIPALITY.NAUCALPAN_DE_JUÁREZ,
    value: MUNICIPALITY.NAUCALPAN_DE_JUÁREZ,
  },
  {
    label: MUNICIPALITY.NEXTLALPAN,
    value: MUNICIPALITY.NEXTLALPAN,
  },
  {
    label: MUNICIPALITY.NEZAHUALCÓYOTL,
    value: MUNICIPALITY.NEZAHUALCÓYOTL,
  },
  {
    label: MUNICIPALITY.NICOLÁS_ROMERO,
    value: MUNICIPALITY.NICOLÁS_ROMERO,
  },
  {
    label: MUNICIPALITY.NOPALTEPEC,
    value: MUNICIPALITY.NOPALTEPEC,
  },
  {
    label: MUNICIPALITY.OCOTLÁN,
    value: MUNICIPALITY.OCOTLÁN,
  },
  {
    label: MUNICIPALITY.OCOYOACAC,
    value: MUNICIPALITY.OCOYOACAC,
  },
  {
    label: MUNICIPALITY.OCUILAN,
    value: MUNICIPALITY.OCUILAN,
  },
  {
    label: MUNICIPALITY.OCUITUCO,
    value: MUNICIPALITY.OCUITUCO,
  },
  {
    label: MUNICIPALITY.OJUELOS_DE_JALISCO,
    value: MUNICIPALITY.OJUELOS_DE_JALISCO,
  },
  {
    label: MUNICIPALITY.OMITLÁN_DE_JUÁREZ,
    value: MUNICIPALITY.OMITLÁN_DE_JUÁREZ,
  },
  {
    label: MUNICIPALITY.OTUMBA,
    value: MUNICIPALITY.OTUMBA,
  },
  {
    label: MUNICIPALITY.OTZOLOTEPEC,
    value: MUNICIPALITY.OTZOLOTEPEC,
  },
  {
    label: MUNICIPALITY.OZUMBA,
    value: MUNICIPALITY.OZUMBA,
  },
  {
    label: MUNICIPALITY.PACHUCA_DE_SOTO,
    value: MUNICIPALITY.PACHUCA_DE_SOTO,
  },
  {
    label: MUNICIPALITY.PAPALOTLA,
    value: MUNICIPALITY.PAPALOTLA,
  },
  {
    label: MUNICIPALITY.PESQUERÍA,
    value: MUNICIPALITY.PESQUERÍA,
  },
  {
    label: MUNICIPALITY.PIHUAMO,
    value: MUNICIPALITY.PIHUAMO,
  },
  {
    label: MUNICIPALITY.PONCITLÁN,
    value: MUNICIPALITY.PONCITLÁN,
  },
  {
    label: MUNICIPALITY.PROGRESO,
    value: MUNICIPALITY.PROGRESO,
  },
  {
    label: MUNICIPALITY.PUERTO_VALLARTA,
    value: MUNICIPALITY.PUERTO_VALLARTA,
  },
  {
    label: MUNICIPALITY.QUERÉTARO,
    value: MUNICIPALITY.QUERÉTARO,
  },
  {
    label: MUNICIPALITY.QUITUPAN,
    value: MUNICIPALITY.QUITUPAN,
  },
  {
    label: MUNICIPALITY.RAYÓN,
    value: MUNICIPALITY.RAYÓN,
  },
  {
    label: MUNICIPALITY.SALINAS_VICTORIA,
    value: MUNICIPALITY.SALINAS_VICTORIA,
  },
  {
    label: MUNICIPALITY.SAN_AGUSTÍN_TLAXIACA,
    value: MUNICIPALITY.SAN_AGUSTÍN_TLAXIACA,
  },
  {
    label: MUNICIPALITY.SAN_ANTONIO_LA_ISLA,
    value: MUNICIPALITY.SAN_ANTONIO_LA_ISLA,
  },
  {
    label: MUNICIPALITY.SAN_CRISTÓBAL_DE_LA_BARRANCA,
    value: MUNICIPALITY.SAN_CRISTÓBAL_DE_LA_BARRANCA,
  },
  {
    label: MUNICIPALITY.SAN_DIEGO_DE_ALEJANDRÍA,
    value: MUNICIPALITY.SAN_DIEGO_DE_ALEJANDRÍA,
  },
  {
    label: MUNICIPALITY.SAN_GABRIEL,
    value: MUNICIPALITY.SAN_GABRIEL,
  },
  {
    label: MUNICIPALITY.SAN_IGNACIO_CERRO_GORDO,
    value: MUNICIPALITY.SAN_IGNACIO_CERRO_GORDO,
  },
  {
    label: MUNICIPALITY.SAN_JOSÉ_DEL_RINCÓN,
    value: MUNICIPALITY.SAN_JOSÉ_DEL_RINCÓN,
  },
  {
    label: MUNICIPALITY.SAN_JOSÉ_GUADALUPE,
    value: MUNICIPALITY.SAN_JOSÉ_GUADALUPE,
  },
  {
    label: MUNICIPALITY.SAN_JUAN_DE_LOS_LAGOS,
    value: MUNICIPALITY.SAN_JUAN_DE_LOS_LAGOS,
  },
  {
    label: MUNICIPALITY.SAN_JUANITO_DE_ESCOBEDO,
    value: MUNICIPALITY.SAN_JUANITO_DE_ESCOBEDO,
  },
  {
    label: MUNICIPALITY.SAN_JULIÁN,
    value: MUNICIPALITY.SAN_JULIÁN,
  },
  {
    label: MUNICIPALITY.SAN_MARCOS,
    value: MUNICIPALITY.SAN_MARCOS,
  },
  {
    label: MUNICIPALITY.SAN_MARTÍN_DE_BOLAÑOS,
    value: MUNICIPALITY.SAN_MARTÍN_DE_BOLAÑOS,
  },
  {
    label: MUNICIPALITY.SAN_MARTÍN_DE_LAS_PIRÁMIDES,
    value: MUNICIPALITY.SAN_MARTÍN_DE_LAS_PIRÁMIDES,
  },
  {
    label: MUNICIPALITY.SAN_MARTÍN_HIDALGO,
    value: MUNICIPALITY.SAN_MARTÍN_HIDALGO,
  },
  {
    label: MUNICIPALITY.SAN_MATEO_ATENCO,
    value: MUNICIPALITY.SAN_MATEO_ATENCO,
  },
  {
    label: MUNICIPALITY.SAN_MIGUEL_EL_ALTO,
    value: MUNICIPALITY.SAN_MIGUEL_EL_ALTO,
  },
  {
    label: MUNICIPALITY.SAN_NICOLÁS_DE_LOS_GARZA,
    value: MUNICIPALITY.SAN_NICOLÁS_DE_LOS_GARZA,
  },
  {
    label: MUNICIPALITY.SAN_PEDRO_GARZA_GARCÍA,
    value: MUNICIPALITY.SAN_PEDRO_GARZA_GARCÍA,
  },
  {
    label: MUNICIPALITY.SAN_PEDRO_TLAQUEPAQUE,
    value: MUNICIPALITY.SAN_PEDRO_TLAQUEPAQUE,
  },
  {
    label: MUNICIPALITY.SAN_SEBASTIÁN_DEL_OESTE,
    value: MUNICIPALITY.SAN_SEBASTIÁN_DEL_OESTE,
  },
  {
    label: MUNICIPALITY.SANTA_CATARINA,
    value: MUNICIPALITY.SANTA_CATARINA,
  },
  {
    label: MUNICIPALITY.SANTA_MARÍA_DE_LOS_ÁNGELES,
    value: MUNICIPALITY.SANTA_MARÍA_DE_LOS_ÁNGELES,
  },
  {
    label: MUNICIPALITY.SANTA_MARÍA_DEL_ORO,
    value: MUNICIPALITY.SANTA_MARÍA_DEL_ORO,
  },
  {
    label: MUNICIPALITY.SANTIAGO,
    value: MUNICIPALITY.SANTIAGO,
  },
  {
    label: MUNICIPALITY.SAYULA,
    value: MUNICIPALITY.SAYULA,
  },
  {
    label: MUNICIPALITY.SUDZAL,
    value: MUNICIPALITY.SUDZAL,
  },
  {
    label: MUNICIPALITY.SUMA,
    value: MUNICIPALITY.SUMA,
  },
  {
    label: MUNICIPALITY.TAHMEK,
    value: MUNICIPALITY.TAHMEK,
  },
  {
    label: MUNICIPALITY.TALA,
    value: MUNICIPALITY.TALA,
  },
  {
    label: MUNICIPALITY.TALPA_DE_ALLENDE,
    value: MUNICIPALITY.TALPA_DE_ALLENDE,
  },
  {
    label: MUNICIPALITY.TAMAZULA_DE_GORDIANO,
    value: MUNICIPALITY.TAMAZULA_DE_GORDIANO,
  },
  {
    label: MUNICIPALITY.TAPALPA,
    value: MUNICIPALITY.TAPALPA,
  },
  {
    label: MUNICIPALITY.TECALITLÁN,
    value: MUNICIPALITY.TECALITLÁN,
  },
  {
    label: MUNICIPALITY.TECÁMAC,
    value: MUNICIPALITY.TECÁMAC,
  },
  {
    label: MUNICIPALITY.TECHALUTA_DE_MONTENEGRO,
    value: MUNICIPALITY.TECHALUTA_DE_MONTENEGRO,
  },
  {
    label: MUNICIPALITY.TECOLOTLÁN,
    value: MUNICIPALITY.TECOLOTLÁN,
  },
  {
    label: MUNICIPALITY.TEMAMATLA,
    value: MUNICIPALITY.TEMAMATLA,
  },
  {
    label: MUNICIPALITY.TEMASCALAPA,
    value: MUNICIPALITY.TEMASCALAPA,
  },
  {
    label: MUNICIPALITY.TEMIXCO,
    value: MUNICIPALITY.TEMIXCO,
  },
  {
    label: MUNICIPALITY.TEMOAYA,
    value: MUNICIPALITY.TEMOAYA,
  },
  {
    label: MUNICIPALITY.TENAMAXTLÁN,
    value: MUNICIPALITY.TENAMAXTLÁN,
  },
  {
    label: MUNICIPALITY.TENANCINGO,
    value: MUNICIPALITY.TENANCINGO,
  },
  {
    label: MUNICIPALITY.TENANGO_DEL_AIRE,
    value: MUNICIPALITY.TENANGO_DEL_AIRE,
  },
  {
    label: MUNICIPALITY.TENANGO_DEL_VALLE,
    value: MUNICIPALITY.TENANGO_DEL_VALLE,
  },
  {
    label: MUNICIPALITY.TEOCALTICHE,
    value: MUNICIPALITY.TEOCALTICHE,
  },
  {
    label: MUNICIPALITY.TEOCUITATLÁN_DE_CORONA,
    value: MUNICIPALITY.TEOCUITATLÁN_DE_CORONA,
  },
  {
    label: MUNICIPALITY.TEOLOYUCAN,
    value: MUNICIPALITY.TEOLOYUCAN,
  },
  {
    label: MUNICIPALITY.TEOTIHUACÁN,
    value: MUNICIPALITY.TEOTIHUACÁN,
  },
  {
    label: MUNICIPALITY.TEPATITLÁN_DE_MORELOS,
    value: MUNICIPALITY.TEPATITLÁN_DE_MORELOS,
  },
  {
    label: MUNICIPALITY.TEPEJI_DEL_RÍO_DE_OCAMPO,
    value: MUNICIPALITY.TEPEJI_DEL_RÍO_DE_OCAMPO,
  },
  {
    label: MUNICIPALITY.TEPETLAOXTOC,
    value: MUNICIPALITY.TEPETLAOXTOC,
  },
  {
    label: MUNICIPALITY.TEPETLIXPA,
    value: MUNICIPALITY.TEPETLIXPA,
  },
  {
    label: MUNICIPALITY.TEPOTZOTLÁN,
    value: MUNICIPALITY.TEPOTZOTLÁN,
  },
  {
    label: MUNICIPALITY.TEPOZTLÁN,
    value: MUNICIPALITY.TEPOZTLÁN,
  },
  {
    label: MUNICIPALITY.TEQUILA,
    value: MUNICIPALITY.TEQUILA,
  },
  {
    label: MUNICIPALITY.TEQUIXQUIAC,
    value: MUNICIPALITY.TEQUIXQUIAC,
  },
  {
    label: MUNICIPALITY.TETELA_DEL_VOLCÁN,
    value: MUNICIPALITY.TETELA_DEL_VOLCÁN,
  },
  {
    label: MUNICIPALITY.TEUCHITLÁN,
    value: MUNICIPALITY.TEUCHITLÁN,
  },
  {
    label: MUNICIPALITY.TEXCALYACAC,
    value: MUNICIPALITY.TEXCALYACAC,
  },
  {
    label: MUNICIPALITY.TEXCOCO,
    value: MUNICIPALITY.TEXCOCO,
  },
  {
    label: MUNICIPALITY.TEZOYUCA,
    value: MUNICIPALITY.TEZOYUCA,
  },
  {
    label: MUNICIPALITY.TIANGUISTENCO,
    value: MUNICIPALITY.TIANGUISTENCO,
  },
  {
    label: MUNICIPALITY.TIXPÉHUAL,
    value: MUNICIPALITY.TIXPÉHUAL,
  },
  {
    label: MUNICIPALITY.TIZAPÁN_EL_ALTO,
    value: MUNICIPALITY.TIZAPÁN_EL_ALTO,
  },
  {
    label: MUNICIPALITY.TIZAYUCA,
    value: MUNICIPALITY.TIZAYUCA,
  },
  {
    label: MUNICIPALITY.TLAHUAPAN,
    value: MUNICIPALITY.TLAHUAPAN,
  },
  {
    label: MUNICIPALITY.TLAJOMULCO,
    value: MUNICIPALITY.TLAJOMULCO,
  },
  {
    label: MUNICIPALITY.TLAJOMULCO_DE_ZÚÑIGA,
    value: MUNICIPALITY.TLAJOMULCO_DE_ZÚÑIGA,
  },
  {
    label: MUNICIPALITY.TLALMANALCO,
    value: MUNICIPALITY.TLALMANALCO,
  },
  {
    label: MUNICIPALITY.TLALNEPANTLA,
    value: MUNICIPALITY.TLALNEPANTLA,
  },
  {
    label: MUNICIPALITY.TLALNEPANTLA_DE_BAZ,
    value: MUNICIPALITY.TLALNEPANTLA_DE_BAZ,
  },
  {
    label: MUNICIPALITY.TLALTIZAPÁN_DE_ZAPATA,
    value: MUNICIPALITY.TLALTIZAPÁN_DE_ZAPATA,
  },
  {
    label: MUNICIPALITY.TLAQUEPAQUE,
    value: MUNICIPALITY.TLAQUEPAQUE,
  },
  {
    label: MUNICIPALITY.TLAYACAPAN,
    value: MUNICIPALITY.TLAYACAPAN,
  },
  {
    label: MUNICIPALITY.TOLCAYUCA,
    value: MUNICIPALITY.TOLCAYUCA,
  },
  {
    label: MUNICIPALITY.TOLIMÁN,
    value: MUNICIPALITY.TOLIMÁN,
  },
  {
    label: MUNICIPALITY.TOLUCA,
    value: MUNICIPALITY.TOLUCA,
  },
  {
    label: MUNICIPALITY.TOMATLÁN,
    value: MUNICIPALITY.TOMATLÁN,
  },
  {
    label: MUNICIPALITY.TONALÁ,
    value: MUNICIPALITY.TONALÁ,
  },
  {
    label: MUNICIPALITY.TONANITLA,
    value: MUNICIPALITY.TONANITLA,
  },
  {
    label: MUNICIPALITY.TONAYA,
    value: MUNICIPALITY.TONAYA,
  },
  {
    label: MUNICIPALITY.TONILA,
    value: MUNICIPALITY.TONILA,
  },
  {
    label: MUNICIPALITY.TOTATICHE,
    value: MUNICIPALITY.TOTATICHE,
  },
  {
    label: MUNICIPALITY.TOTOLAPAN,
    value: MUNICIPALITY.TOTOLAPAN,
  },
  {
    label: MUNICIPALITY.TOTOTLÁN,
    value: MUNICIPALITY.TOTOTLÁN,
  },
  {
    label: MUNICIPALITY.TULTEPEC,
    value: MUNICIPALITY.TULTEPEC,
  },
  {
    label: MUNICIPALITY.TULTITLÁN,
    value: MUNICIPALITY.TULTITLÁN,
  },
  {
    label: MUNICIPALITY.TUXCACUESCO,
    value: MUNICIPALITY.TUXCACUESCO,
  },
  {
    label: MUNICIPALITY.TUXCUECA,
    value: MUNICIPALITY.TUXCUECA,
  },
  {
    label: MUNICIPALITY.TUXPAN,
    value: MUNICIPALITY.TUXPAN,
  },
  {
    label: MUNICIPALITY.UNIÓN_DE_SAN_ANTONIO,
    value: MUNICIPALITY.UNIÓN_DE_SAN_ANTONIO,
  },
  {
    label: MUNICIPALITY.UNIÓN_DE_TULA,
    value: MUNICIPALITY.UNIÓN_DE_TULA,
  },
  {
    label: MUNICIPALITY.VALLE_DE_CHALCO_SOLIDARIDAD,
    value: MUNICIPALITY.VALLE_DE_CHALCO_SOLIDARIDAD,
  },
  {
    label: MUNICIPALITY.VALLE_DE_GUADALUPE,
    value: MUNICIPALITY.VALLE_DE_GUADALUPE,
  },
  {
    label: MUNICIPALITY.VALLE_DE_JUÁREZ,
    value: MUNICIPALITY.VALLE_DE_JUÁREZ,
  },
  {
    label: MUNICIPALITY.VERACRUZ,
    value: MUNICIPALITY.VERACRUZ,
  },
  {
    label: MUNICIPALITY.VILLA_CORONA,
    value: MUNICIPALITY.VILLA_CORONA,
  },
  {
    label: MUNICIPALITY.VILLA_DEL_CARBÓN,
    value: MUNICIPALITY.VILLA_DEL_CARBÓN,
  },
  {
    label: MUNICIPALITY.VILLA_GUERRERO,
    value: MUNICIPALITY.VILLA_GUERRERO,
  },
  {
    label: MUNICIPALITY.VILLA_HIDALGO,
    value: MUNICIPALITY.VILLA_HIDALGO,
  },
  {
    label: MUNICIPALITY.VILLA_PURIFICACIÓN,
    value: MUNICIPALITY.VILLA_PURIFICACIÓN,
  },
  {
    label: MUNICIPALITY.XOCHITEPEC,
    value: MUNICIPALITY.XOCHITEPEC,
  },
  {
    label: MUNICIPALITY.XONACATLÁN,
    value: MUNICIPALITY.XONACATLÁN,
  },
  {
    label: MUNICIPALITY.YAHUALICA_DE_GONZÁLEZ_GALLO,
    value: MUNICIPALITY.YAHUALICA_DE_GONZÁLEZ_GALLO,
  },
  {
    label: MUNICIPALITY.YAUTEPEC,
    value: MUNICIPALITY.YAUTEPEC,
  },
  {
    label: MUNICIPALITY.YECAPIXTLA,
    value: MUNICIPALITY.YECAPIXTLA,
  },
  {
    label: MUNICIPALITY.ZACOALCO_DE_TORRES,
    value: MUNICIPALITY.ZACOALCO_DE_TORRES,
  },
  {
    label: MUNICIPALITY.ZACUALPAN,
    value: MUNICIPALITY.ZACUALPAN,
  },
  {
    label: MUNICIPALITY.ZAPOPAN,
    value: MUNICIPALITY.ZAPOPAN,
  },
  {
    label: MUNICIPALITY.ZAPOTILTIC,
    value: MUNICIPALITY.ZAPOTILTIC,
  },
  {
    label: MUNICIPALITY.ZAPOTITLÁN_DE_VADILLO,
    value: MUNICIPALITY.ZAPOTITLÁN_DE_VADILLO,
  },
  {
    label: MUNICIPALITY.ZAPOTLÁN_DE_JUÁREZ,
    value: MUNICIPALITY.ZAPOTLÁN_DE_JUÁREZ,
  },
  {
    label: MUNICIPALITY.ZAPOTLÁN_DEL_REY,
    value: MUNICIPALITY.ZAPOTLÁN_DEL_REY,
  },
  {
    label: MUNICIPALITY.ZAPOTLÁN_EL_GRANDE,
    value: MUNICIPALITY.ZAPOTLÁN_EL_GRANDE,
  },
  {
    label: MUNICIPALITY.ZAPOTLANEJO,
    value: MUNICIPALITY.ZAPOTLANEJO,
  },
  {
    label: MUNICIPALITY.ZEMPOALA,
    value: MUNICIPALITY.ZEMPOALA,
  },
  {
    label: MUNICIPALITY.ZINACANTEPEC,
    value: MUNICIPALITY.ZINACANTEPEC,
  },
  {
    label: MUNICIPALITY.ZUMPAHUACÁN,
    value: MUNICIPALITY.ZUMPAHUACÁN,
  },
  {
    label: MUNICIPALITY.ZUMPANGO,
    value: MUNICIPALITY.ZUMPANGO,
  },
];

export const OPTIONS_PROVINCIA = [
  {
    label: PROVINCES.AGUASCALIENTES,
    value: PROVINCES.AGUASCALIENTES,
  },
  {
    label: PROVINCES.BAJA_CALIFORNIA,
    value: PROVINCES.BAJA_CALIFORNIA,
  },
  {
    label: PROVINCES.BAJA_CALIFORNIA_SUR,
    value: PROVINCES.BAJA_CALIFORNIA_SUR,
  },
  {
    label: PROVINCES.CAMPECHE,
    value: PROVINCES.CAMPECHE,
  },
  {
    label: PROVINCES.CHIAPAS,
    value: PROVINCES.CHIAPAS,
  },
  {
    label: PROVINCES.CHIHUAHUA,
    value: PROVINCES.CHIHUAHUA,
  },
  {
    label: PROVINCES.CIUDAD_DE_MÉXICO,
    value: PROVINCES.CIUDAD_DE_MÉXICO,
  },
  {
    label: PROVINCES.COAHUILA,
    value: PROVINCES.COAHUILA,
  },
  {
    label: PROVINCES.COLIMA,
    value: PROVINCES.COLIMA,
  },
  {
    label: PROVINCES.DURANGO,
    value: PROVINCES.DURANGO,
  },
  {
    label: PROVINCES.GUANAJUATO,
    value: PROVINCES.GUANAJUATO,
  },
  {
    label: PROVINCES.GUERRERO,
    value: PROVINCES.GUERRERO,
  },
  {
    label: PROVINCES.HIDALGO,
    value: PROVINCES.HIDALGO,
  },
  {
    label: PROVINCES.JALISCO,
    value: PROVINCES.JALISCO,
  },
  {
    label: PROVINCES.MÉXICO,
    value: PROVINCES.MÉXICO,
  },
  {
    label: PROVINCES.MICHOACÁN,
    value: PROVINCES.MICHOACÁN,
  },
  {
    label: PROVINCES.MORELOS,
    value: PROVINCES.MORELOS,
  },
  {
    label: PROVINCES.NAYARIT,
    value: PROVINCES.NAYARIT,
  },
  {
    label: PROVINCES.NUEVO_LEÓN,
    value: PROVINCES.NUEVO_LEÓN,
  },
  {
    label: PROVINCES.OAXACA,
    value: PROVINCES.OAXACA,
  },
  {
    label: PROVINCES.PUEBLA,
    value: PROVINCES.PUEBLA,
  },
  {
    label: PROVINCES.QUERÉTARO,
    value: PROVINCES.QUERÉTARO,
  },
  {
    label: PROVINCES.QUINTANA_ROO,
    value: PROVINCES.QUINTANA_ROO,
  },
  {
    label: PROVINCES.SAN_LUIS_POTOSÍ,
    value: PROVINCES.SAN_LUIS_POTOSÍ,
  },
  {
    label: PROVINCES.SINALOA,
    value: PROVINCES.SINALOA,
  },
  {
    label: PROVINCES.SONORA,
    value: PROVINCES.SONORA,
  },
  {
    label: PROVINCES.TABASCO,
    value: PROVINCES.TABASCO,
  },
  {
    label: PROVINCES.TAMAULIPAS,
    value: PROVINCES.TAMAULIPAS,
  },
  {
    label: PROVINCES.TLAXCALA,
    value: PROVINCES.TLAXCALA,
  },
  {
    label: PROVINCES.VERACRUZ,
    value: PROVINCES.VERACRUZ,
  },
  {
    label: PROVINCES.YUCATÁN,
    value: PROVINCES.YUCATÁN,
  },
  {
    label: PROVINCES.ZACATECAS,
    value: PROVINCES.ZACATECAS,
  },
];
