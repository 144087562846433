import styled from "styled-components";
import { electricPurple500, size } from "@habitech/shared";

export const PaymentProcessWrapper = styled.main`
  padding: 16px;
  .flex {
    display: flex;
  }
  .justify-space-between {
    justify-content: space-between;
  }
  .gap-12 {
    gap: 12px;
  }
  .main-container {
    max-width: 330px;
    min-width: 330px;
    margin: 0 auto;
    .hero {
      display: none;
    }
    .header-container {
      margin-bottom: 41px;
      img {
        display: none;
      }
      &-title {
        letter-spacing: 2px;
        margin-bottom: 12px;
        text-transform: uppercase;
      }
      &-content {
        line-height: 32px;
      }
    }
    .property-information-container {
      .property-information {
        &-title {
          letter-spacing: 2px;
          margin-bottom: 8px;
        }
        &-name {
          margin-bottom: 8px;
        }
        &-location {
          margin-bottom: 16px;
        }
      }
      hr {
        margin-bottom: 16px;
      }
      .values-container {
        /* max-width: 313px; */
        .value {
          margin-bottom: 12px;
        }
        .advance {
          margin-bottom: 24px;
        }
      }
      button {
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  @media screen and (min-width: ${size.tablet.min}) {
    .main-container {
      min-width: none;
      max-width: 504px;
      .hero {
        background-color: ${electricPurple500};
        border-radius: 8px;
        width: 100%;
        height: 87px;
        margin-bottom: 38px;
        display: block;
        img {
          width: 100%;
          height: inherit;
        }
      }
      .header-container {
        position: relative;
        img {
          position: absolute;
          display: block;
          top: -20px;
          right: 0;
          z-index: -1;
        }
        &-content {
          font: 600 32px Montserrat;
          line-height: 40px;
        }
      }
      .property-information-container {
        .property-information-name {
          font-size: 24px;
        }
        .values-container {
          max-width: 70%;
        }
      }
    }
  }

  @media screen and (min-width: ${size.desktop.min}) {
    .main-container {
      min-width: none;
      max-width: 598px;
      .hero {
        margin-bottom: 53px;
      }
      .header-container {
        img {
          position: absolute;
          top: -20px;
          right: 65px;
          z-index: -1;
        }
        &-content {
          font: 600 32px Montserrat;
          line-height: 40px;
        }
      }
    }
  }
`;
