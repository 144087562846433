import { FC } from "react";

// Components
import HeaderMx from "../HeaderMx/HeaderMx";
import Clarifications from "../Clarifications/Clarifications";
import AuthorizationParagraphMx from "../AuthorizationParagraphMx/AuthorizationParagraphMx";

// Styles
import { TermsWrapper } from "../TermsWrapper";

// helpers
import { formatDateCo } from "utils/scripts/formatDateCo";

interface TermsMxProps {
  data: {
    nid: string;
    idx: string;
    card_id: string;
    country: string;
    colonia: string;
    municipio: string;
    rfc_cliente: string;
    curp_cliente: string;
    numero_interior: string;
    numero_exterior: string;
    calle_avenida_via: string;
    estado_o_provincia: string;
    nombre_del_cliente: string;
    numero_de_clientes: string;
    nacionalidad_cliente: string;
    telefono_del_cliente: string;
    estado_civil_del_cliente: string;
    precio_final_del_inmueble: string;
    nombre_de_edificio_conjunto: string;
    apellido_materno_propietario: string;
    apellido_paterno_propietario: string;
    correo_electronico_del_cliente: string;
    numero_de_identificacion_cliente: string;
    tipo_de_identificacion_del_cliente: string;
  };
}

const TermsMx: FC<TermsMxProps> = ({ data }) => {
  const {
    colonia = "",
    curp_cliente = "",
    correo_electronico_del_cliente = "",
    numero_de_identificacion_cliente = "",
    tipo_de_identificacion_del_cliente = "",
    apellido_materno_propietario = "",
    estado_civil_del_cliente = "",
    nacionalidad_cliente = "",
    apellido_paterno_propietario = "",
    telefono_del_cliente = "",
    numero_de_clientes = "",
    rfc_cliente = "",
    nombre_del_cliente = "",
    numero_interior = "",
    municipio = "",
    numero_exterior = "",
    nid = "",
    nombre_de_edificio_conjunto = "",
    precio_final_del_inmueble = "",
    estado_o_provincia = "",
    calle_avenida_via = "",
    idx = "",
    card_id = "",
    country = "",
  } = data;

  return (
    <TermsWrapper>
      <main className="content">
        <HeaderMx />
        <section className="content-header">
          <h1 className="content-header-title">
            Ciudad de Mexico D.C, {formatDateCo(new Date().toDateString())}
          </h1>
          <div className="content-header-intro">
            <p>Señores</p>
            <p>Tuhabi</p>
            <p>Ref. Oferta de Compra</p>
          </div>
        </section>
        <section className="content-main">
          De acuerdo con la visita realizadada al inmueble{" "}
          <strong>{nombre_de_edificio_conjunto}</strong> queremos realizar una
          oferta de compra por un valor de:{" "}
          <strong>{precio_final_del_inmueble}.</strong>
        </section>
      </main>
      <div className="content-main">Atentamente,</div>
      <footer className="footer">
        <div className="footer-properties">
          <div className="footer-property">
            <label className="footer-property-label">Nombre</label>
            <h2 className="footer-property-value">{nombre_del_cliente}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">Apellido paterno</label>
            <h2 className="footer-property-value">
              {apellido_paterno_propietario}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">Apellido materno</label>
            <h2 className="footer-property-value">
              {apellido_materno_propietario}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">Nacionalidad </label>
            <h2 className="footer-property-value">{nacionalidad_cliente}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">Estado civil</label>
            <h2 className="footer-property-value">
              {estado_civil_del_cliente}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">RFC</label>
            <h2 className="footer-property-value">{rfc_cliente}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">CURP</label>
            <h2 className="footer-property-value">{curp_cliente}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Tipo de identificación
            </label>
            <h2 className="footer-property-value">
              {tipo_de_identificacion_del_cliente}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Número del documento seleccionado
            </label>
            <h2 className="footer-property-value">
              {numero_de_identificacion_cliente}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">Correo electrónico</label>
            <h2 className="footer-property-value">
              {correo_electronico_del_cliente}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Estado o provincia de residencia
            </label>
            <h2 className="footer-property-value">{estado_o_provincia}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Municipio de residencia
            </label>
            <h2 className="footer-property-value">{municipio}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Colonia de residencia
            </label>
            <h2 className="footer-property-value">{colonia}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Calle, avenida o vía principal de residencia
            </label>
            <h2 className="footer-property-value">{calle_avenida_via}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Número exterior de dirección de residencia
            </label>
            <h2 className="footer-property-value">{numero_exterior}</h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Número interior de dirección de residencia
            </label>
            <h2 className="footer-property-value">{numero_interior}</h2>
          </div>
        </div>
      </footer>
    </TermsWrapper>
  );
};

export default TermsMx;
