import { combineReducers } from "@reduxjs/toolkit";
import { affidavitApi } from "services/affidavitApi";
import affidavitReducer from "./affidavit/affidavitSlice";
import affidavitReducerMx from "./affidavitMx/affidavitMxSlice";
import { affidavitMxApi } from "services/affidavitMxApi";

export default combineReducers({
  affidavitReducer,
  affidavitReducerMx,
  [affidavitApi.reducerPath]: affidavitApi.reducer,
  [affidavitMxApi.reducerPath]: affidavitMxApi.reducer,
});
