import React, { useEffect, useState } from "react";
import { FormWrapper } from "./styles";

// Components
import { Checkbox } from "@habitech/selectors";
import { DropdownButton } from "@habitech/call-to-action";
import RadioButton from "./RadioButton/RadioButton";
import { optionsSelect } from "../../constants";

const AcceptTermsAndConditionsMx = ({ handleChange, form, data }) => {
  const { nombre_de_edificio_conjunto } = data;
  const onChangeValueField = (name, value) => {
    handleChange((prev) => {
      const prevCopy = { ...prev };
      prevCopy[name] = value;
      return prevCopy;
    });
  };

  return (
    <FormWrapper>
      <div className="footer_form_terms">
        <div className="form-item-radio-buttons">
          <p className="label-field">
            Conocí e ingresé al inmueble de forma presencial:
          </p>
          <div className="radio-buttons-container">
            <RadioButton
              handleChange={onChangeValueField}
              value="Si"
              label="Si"
              name="el_cliente_conocio_el_inmueble"
            />
            <RadioButton
              handleChange={onChangeValueField}
              value="No"
              label="No"
              name="el_cliente_conocio_el_inmueble"
            />
          </div>
        </div>
        <div className="form-item-radio-buttons">
          <p className="label-field">
            Estoy interesado en comprar el inmueble denominado{" "}
            <b>{nombre_de_edificio_conjunto}</b>:
          </p>
          <div className="radio-buttons-container">
            <RadioButton
              handleChange={onChangeValueField}
              value="Si"
              label="Si"
              name="el_cliente_esta_interesado_en_comprar_el_inmueble"
            />
            <RadioButton
              handleChange={onChangeValueField}
              value="No"
              label="No"
              name="el_cliente_esta_interesado_en_comprar_el_inmueble"
            />
          </div>
        </div>
        <div className="form-item-radio-buttons">
          <p className="label-field">
            ¿Su asesor le ofreció nuestro servicio de crédito Habicredit?
          </p>
          <div className="selects-container">
            <DropdownButton
              size="fluid"
              variant="white"
              dataId="test_id"
              name={"al_cliente_le_ofrecieron_habicredit"}
              listOptions={optionsSelect}
              placeholder="Seleccione"
              setValue={(value) =>
                onChangeValueField("al_cliente_le_ofrecieron_habicredit", value)
              }
            />
          </div>
        </div>
        <div className="form-item-radio-buttons last-item">
          <p className="label-field">
            ¿Le ofrecieron financiar la compra de su inmueble con el banco
            Banorte?
          </p>
          <div className="selects-container">
            <DropdownButton
              size="fluid"
              variant="white"
              dataId="test_id"
              name={"al_cliente_le_ofrecieron_banorte"}
              listOptions={optionsSelect}
              placeholder="Seleccione"
              setValue={(value) =>
                onChangeValueField("al_cliente_le_ofrecieron_banorte", value)
              }
            />
          </div>
        </div>
        <div className="form-item">
          <Checkbox
            dataId="test-data-id"
            id="test-id"
            name="terms"
            onChange={(e, isChecked) => {
              if (isChecked) {
                return onChangeValueField("terms", e);
              }
              return onChangeValueField("terms", "");
            }}
            type="default"
            value="accept"
          />
          <label htmlFor="terms">
            <span className="footer_form_label">
              Acepto los <strong>Términos y Condiciones</strong> descritos en
              este contrato
            </span>
          </label>
        </div>
      </div>
    </FormWrapper>
  );
};

export default AcceptTermsAndConditionsMx;
