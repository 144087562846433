import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AffidavitMxState, FormData } from "./models/affidavitMxSlice.model";
import { formState } from "./defaultState";

const initialState: AffidavitMxState = {
  stage: "central_risk",
  progress: 30,
  formState,
  infoUser: {},
};

export const affidavitMxSlice = createSlice({
  name: "affidavitMx",
  initialState,
  reducers: {
    changeStage: (state, action: PayloadAction<AffidavitMxState>) => {
      state.stage = action.payload.stage;
      state.progress = action.payload.progress;
      state.infoUser = action.payload.infoUser;
    },
    changeForm: (state, action: PayloadAction<FormData>) => {
      state.formState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeStage, changeForm } = affidavitMxSlice.actions;

export default affidavitMxSlice.reducer;
