import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

// Components
import NotFound from "pages/NotFound";
import ConfirmData from "./ConfirmData/ConfirmData";
import Loader from "../../components/loader";
import Terms from "pages/Home/TermsAndConditions/TermsAndConditions";

// Services
import { postAcceptConditions } from "services/api/form/accept";
import { useDecrypt } from "../../services/home";

// Styles
import { HomeWrapper } from "./styles";
import {
  encodedData,
  updateTruoraData,
} from "../../services/api/truora/truora";
import { ALL_ROUTES } from "../../constants/routes";

import { formConfigMx } from "./ConfirmData/config/formConfigMx";
import { formConfigCo } from "./ConfirmData/config/formConfigCo";
const Home = () => {
  const [searchParams] = useSearchParams();
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [editData, setEditData] = useState(false);
  const [newData, setNewData] = useState(undefined);
  const navigate = useNavigate();

  const token = searchParams.get("token");

  const { data, isLoading, isError } = useDecrypt({ token });

  useEffect(() => {
    setNewData(data);
  }, [data]);

  if (isError) return <NotFound />;
  if (isLoading || !data) return <Loader scale={1} />;

  const handleAccept = async (termsAndConditionData) => {
    setLoadingAccept(true);
    try {
      const { terms, ...rest } = termsAndConditionData;
      const dataToEncoded = {
        ...newData,
        ...rest,
      };
      const responseWithToken = await encodedData(dataToEncoded);
      const dataSend = {
        token: responseWithToken.data.token,
        country: newData?.country,
      };
      await updateTruoraData(dataSend);
      navigate(`${ALL_ROUTES.SUCCESS_PROGRESS}/${newData?.country}`);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingAccept(false);
    }
  };

  const getFormConfigByCountry = () => {
    return (
      {
        MX: formConfigMx,
      }[newData?.country || ""] || formConfigCo
    );
  };

  return (
    <HomeWrapper>
      {editData ? (
        <ConfirmData
          handleChangeData={setNewData}
          isEditData={setEditData}
          data={newData}
          formConfig={getFormConfigByCountry()}
        />
      ) : (
        <Terms
          data={newData}
          handleChange={handleAccept}
          loading={loadingAccept}
          handleEdit={setEditData}
        />
      )}
    </HomeWrapper>
  );
};

export default Home;
