import { IConfig, IDataTruora } from "models";

export const getDefaultMatchValues = (
  formConfig: IConfig[],
  data: IDataTruora
): IConfig[] => {
  const dataMerged = formConfig?.map((config) => {
    const copyConfig = { ...config };
    if (data[config.name]) {
      copyConfig.value = data[config.name];
      return copyConfig;
    }
    return config;
  });
  return dataMerged;
};
