import { FormData } from "./models/affidavitSlice.model";

export const formState: FormData = {
  contact: {
    title: "Información de contacto",
    state: [
      {
        type: "dropdown",
        options: [
          { label: "Afghanistan", value: "AX" },
          { label: "Albania", value: "AL" },
          { label: "Alemania", value: "DE" },
          { label: "Andorra", value: "AD" },
          { label: "Angola", value: "AO" },
          { label: "Antigua y Barbuda", value: "AG" },
          { label: "Arabia Saudita", value: "SA" },
          { label: "Argelia", value: "DZ" },
          { label: "Argentina", value: "AR" },
          { label: "Armenia", value: "AM" },
          { label: "Australia", value: "AU" },
          { label: "Austria", value: "AT" },
          { label: "Azerbaiyán", value: "AZ" },
          { label: "Bahamas", value: "BS" },
          { label: "Bangladés", value: "BD" },
          { label: "Barbados", value: "BB" },
          { label: "Baréin", value: "BH" },
          { label: "Bélgica", value: "BE" },
          { label: "Belice", value: "BZ" },
          { label: "Benín", value: "BJ" },
          { label: "Bielorrusia", value: "BY" },
          { label: "Birmania", value: "MM" },
          { label: "Bolivia", value: "BO" },
          { label: "Bosnia y Herzegovina", value: "BA" },
          { label: "Botsuana", value: "BW" },
          { label: "Brasil", value: "BR" },
          { label: "Brunéi", value: "BN" },
          { label: "Bulgaria", value: "BG" },
          { label: "Burkina Faso", value: "BF" },
          { label: "Burundi", value: "BI" },
          { label: "Bután", value: "BT" },
          { label: "Cabo Verde", value: "CV" },
          { label: "Camboya", value: "KH" },
          { label: "Camerún", value: "CM" },
          { label: "Canadá", value: "CA" },
          { label: "Catar", value: "QA" },
          { label: "Chad", value: "TD" },
          { label: "Chile", value: "CL" },
          { label: "China", value: "CN" },
          { label: "Chipre", value: "CY" },
          { label: "Ciudad del Vaticano", value: "VA" },
          { label: "Colombia", value: "CO" },
          { label: "Comoras", value: "KM" },
          { label: "Corea del Norte", value: "KP" },
          { label: "Corea del Sur", value: "KP" },
          { label: "Costa de Marfil", value: "CI" },
          { label: "Costa Rica", value: "CR" },
          { label: "Croacia", value: "HR" },
          { label: "Cuba", value: "CU" },
          { label: "Dinamarca", value: "DK" },
          { label: "Dominica", value: "DM" },
          { label: "Ecuador", value: "EC" },
          { label: "Egipto", value: "EG" },
          { label: "El Salvador", value: "SV" },
          { label: "Emiratos Árabes Unidos", value: "AE" },
          { label: "Eritrea", value: "ER" },
          { label: "Eslovaquia", value: "SK" },
          { label: "Eslovenia", value: "SI" },
          { label: "España", value: "ES" },
          { label: "Estados Unidos", value: "US" },
          { label: "Estonia", value: "EE" },
          { label: "Etiopía", value: "ET" },
          { label: "Filipinas", value: "PH" },
          { label: "Finlandia", value: "FI" },
          { label: "Fiyi", value: "FJ" },
          { label: "Francia", value: "FR" },
          { label: "Gabón", value: "GA" },
          { label: "Gambia", value: "GM" },
          { label: "Georgia", value: "GE" },
          { label: "Ghana", value: "GH" },
          { label: "Granada", value: "GD" },
          { label: "Grecia", value: "GR" },
          { label: "Guatemala", value: "GT" },
          { label: "Guyana", value: "GY" },
          { label: "Guinea", value: "GN" },
          { label: "Guinea ecuatorial", value: "GQ" },
          { label: "Guinea-Bisáu", value: "GW" },
          { label: "Haití", value: "HT" },
          { label: "Honduras", value: "HN" },
          { label: "Hungría", value: "HU" },
          { label: "India", value: "IN" },
          { label: "Indonesia", value: "ID" },
          { label: "Irán", value: "IR" },
          { label: "Irak", value: "IQ" },
          { label: "Irlanda", value: "IE" },
          { label: "Islandia", value: "IS" },
          { label: "Islas Marshall", value: "MH" },
          { label: "Islas Salomón", value: "SB" },
          { label: "Israel", value: "IL" },
          { label: "Italia", value: "IT" },
          { label: "Jamaica", value: "JM" },
          { label: "Japón", value: "JP" },
          { label: "Jordania", value: "JO" },
          { label: "Kazajistán", value: "KZ" },
          { label: "Kenia", value: "KE" },
          { label: "Kirguistán", value: "KG" },
          { label: "Kiribati", value: "KI" },
          { label: "Kuwait", value: "KW" },
          { label: "Laos", value: "LA" },
          { label: "Lesoto", value: "LS" },
          { label: "Letonia", value: "LV" },
          { label: "Líbano", value: "LB" },
          { label: "Liberia", value: "LR" },
          { label: "Libia", value: "LY" },
          { label: "Liechtenstein", value: "LI" },
          { label: "Lituania", value: "LT" },
          { label: "Luxemburgo", value: "LU" },
          { label: "Macedonia del Norte", value: "MK" },
          { label: "Madagascar", value: "MG" },
          { label: "Malasia", value: "MY" },
          { label: "Malaui", value: "MW" },
          { label: "Maldivas", value: "MV" },
          { label: "Malí", value: "ML" },
          { label: "Malta", value: "MT" },
          { label: "Marruecos", value: "MA" },
          { label: "Mauricio", value: "MU" },
          { label: "Mauritania", value: "MR" },
          { label: "México", value: "MX" },
          { label: "Micronesia", value: "FM" },
          { label: "Moldavia", value: "MD" },
          { label: "Mónaco", value: "MC" },
          { label: "Mongolia", value: "MN" },
          { label: "Montenegro", value: "ME" },
          { label: "Mozambique", value: "MZ" },
          { label: "Namibia", value: "NA" },
          { label: "Nauru", value: "NR" },
          { label: "Nepal", value: "NP" },
          { label: "Nicaragua", value: "NI" },
          { label: "Níger", value: "NE" },
          { label: "Nigeria", value: "NG" },
          { label: "Noruega", value: "NO" },
          { label: "Nueva Zelanda", value: "NZ" },
          { label: "Omán", value: "OM" },
          { label: "Países Bajos", value: "NL" },
          { label: "Pakistán", value: "PK" },
          { label: "Palaos", value: "PW" },
          { label: "Panamá", value: "PA" },
          { label: "Papúa Nueva Guinea", value: "PG" },
          { label: "Paraguay", value: "PY" },
          { label: "Perú", value: "PE" },
          { label: "Polonia", value: "PL" },
          { label: "Portugal", value: "PT" },
          {
            label: "Reino Unido",
            value: "GB",
          },
          { label: "República Centroafricana", value: "CF" },
          { label: "República Checa", value: "CZ" },
          { label: "República del Congo", value: "RDC" },
          { label: "República Democrática del Congo", value: "COD" },
          { label: "República Dominicana", value: "DO" },
          { label: "Ruanda", value: "RW" },
          { label: "Rumanía", value: "RO" },
          { label: "Rusia", value: "RU" },
          { label: "Samoa", value: "WS" },
          { label: "San Cristóbal y Nieves", value: "SKN" },
          { label: "San Marino", value: "SM" },
          { label: "San Vicente y las Granadinas", value: "VC" },
          { label: "Santa Lucía", value: "LC" },
          { label: "Santo Tomé y Príncipe", value: "ST" },
          { label: "Senegal", value: "SN" },
          { label: "Serbia", value: "RS" },
          { label: "Seychelles", value: "SC" },
          { label: "Sierra Leona", value: "SL" },
          { label: "Singapur", value: "SG" },
          { label: "Siria", value: "SY" },
          { label: "Somalia", value: "SO" },
          { label: "Sri Lanka", value: "LK" },
          { label: "Suazilandia", value: "SZ" },
          { label: "Sudáfrica", value: "ZA" },
          { label: "Sudán", value: "SD" },
          { label: "Sudán del Sur", value: "SS" },
          { label: "Suecia", value: "SWE" },
          { label: "Suiza", value: "SUI" },
          { label: "Surinam", value: "SR" },
          { label: "Tailandia", value: "TH" },
          { label: "Tanzania", value: "TZ" },
          { label: "Tayikistán", value: "TJ" },
          { label: "Timor Oriental", value: "TL" },
          { label: "Togo", value: "TG" },
          { label: "Tonga", value: "TO" },
          { label: "Trinidad y Tobago", value: "TT" },
          { label: "Túnez", value: "TN" },
          { label: "Turkmenistán", value: "TM" },
          { label: "Turquía", value: "TR" },
          { label: "Tuvalu", value: "TV" },
          { label: "Ucrania", value: "UA" },
          { label: "Uganda", value: "UG" },
          { label: "Uruguay", value: "UY" },
          { label: "Uzbekistán", value: "UZ" },
          { label: "Vanuatu", value: "CH" },
          { label: "Venezuela", value: "VE" },
          { label: "Vietnam", value: "VN" },
          { label: "Yemen", value: "YE" },
          { label: "Yibuti", value: "DJ" },
          { label: "Zambia", value: "ZM" },
          { label: "Zimbabue", value: "ZW" },
        ],
        name: "country",
        label: "¿Cuál es tu país de residencia?",
        placeholder: "Selecciona o escribe tu país",
      },
      {
        type: "input",
        value: "",
        name: "city",
        label: "¿En qué ciudad vives?",
        placeholder: "Selecciona tu Ciudad",
      },
      {
        type: "dropdown",
        options: [
          {
            "value": "Bogotá",
            "label": "Bogotá"
          },
          {
            "value": "Soacha",
            "label": "Soacha"
          },
          {
            "value": "Mosquera",
            "label": "Mosquera"
          },
          {
            "value": "Funza",
            "label": "Funza"
          },
          {
            "value": "Madrid",
            "label": "Madrid"
          },
          {
            "value": "Chía",
            "label": "Chía"
          },
          {
            "value": "Cajicá",
            "label": "Cajicá"
          },
          {
            "value": "Cota",
            "label": "Cota"
          },
          {
            "value": "La Calera",
            "label": "La Calera"
          },
          {
            "value": "Tenjo",
            "label": "Tenjo"
          },
          {
            "value": "Tabio",
            "label": "Tabio"
          },
          {
            "value": "Sibaté",
            "label": "Sibaté"
          },
          {
            "value": "Zipaquirá",
            "label": "Zipaquirá"
          },
          {
            "value": "Facatativá",
            "label": "Facatativá"
          },
          {
            "value": "Bojacá",
            "label": "Bojacá"
          },
          {
            "value": "Gachancipá",
            "label": "Gachancipá"
          },
          {
            "value": "Tocancipá",
            "label": "Tocancipá"
          },
          {
            "value": "Sopó",
            "label": "Sopó"
          },
          {
            "value": "Barranquilla",
            "label": "Barranquilla"
          },
          {
            "value": "Cali",
            "label": "Cali"
          },
          {
            "value": "Abejorral",
            "label": "Abejorral"
          },
          {
            "value": "Abriaquí",
            "label": "Abriaquí"
          },
          {
            "value": "Alejandría",
            "label": "Alejandría"
          },
          {
            "value": "Amagá",
            "label": "Amagá"
          },
          {
            "value": "Amalfi",
            "label": "Amalfi"
          },
          {
            "value": "Andes",
            "label": "Andes"
          },
          {
            "value": "Angelópolis",
            "label": "Angelópolis"
          },
          {
            "value": "Angostura",
            "label": "Angostura"
          },
          {
            "value": "Anorí",
            "label": "Anorí"
          },
          {
            "value": "Anzá",
            "label": "Anzá"
          },
          {
            "value": "Apartadó",
            "label": "Apartadó"
          },
          {
            "value": "Arboletes",
            "label": "Arboletes"
          },
          {
            "value": "Argelia",
            "label": "Argelia"
          },
          {
            "value": "Barbosa",
            "label": "Barbosa"
          },
          {
            "value": "Bello",
            "label": "Bello"
          },
          {
            "value": "Belmira",
            "label": "Belmira"
          },
          {
            "value": "Betania",
            "label": "Betania"
          },
          {
            "value": "Betulia",
            "label": "Betulia"
          },
          {
            "value": "Briceño",
            "label": "Briceño"
          },
          {
            "value": "Buriticá",
            "label": "Buriticá"
          },
          {
            "value": "Cáceres",
            "label": "Cáceres"
          },
          {
            "value": "Caicedo",
            "label": "Caicedo"
          },
          {
            "value": "Caldas",
            "label": "Caldas"
          },
          {
            "value": "Campamento",
            "label": "Campamento"
          },
          {
            "value": "Cañasgordas",
            "label": "Cañasgordas"
          },
          {
            "value": "Caracolí",
            "label": "Caracolí"
          },
          {
            "value": "Caramanta",
            "label": "Caramanta"
          },
          {
            "value": "Carepa",
            "label": "Carepa"
          },
          {
            "value": "Carolina del Príncipe",
            "label": "Carolina del Príncipe"
          },
          {
            "value": "Caucasia",
            "label": "Caucasia"
          },
          {
            "value": "Chigorodó",
            "label": "Chigorodó"
          },
          {
            "value": "Cisneros",
            "label": "Cisneros"
          },
          {
            "value": "Ciudad Bolívar",
            "label": "Ciudad Bolívar"
          },
          {
            "value": "Cocorná",
            "label": "Cocorná"
          },
          {
            "value": "Concepción",
            "label": "Concepción"
          },
          {
            "value": "Concordia",
            "label": "Concordia"
          },
          {
            "value": "Copacabana",
            "label": "Copacabana"
          },
          {
            "value": "Dabeiba",
            "label": "Dabeiba"
          },
          {
            "value": "Donmatías",
            "label": "Donmatías"
          },
          {
            "value": "Ebéjico",
            "label": "Ebéjico"
          },
          {
            "value": "El Bagre",
            "label": "El Bagre"
          },
          {
            "value": "El Carmen de Viboral",
            "label": "El Carmen de Viboral"
          },
          {
            "value": "El Peñol",
            "label": "El Peñol"
          },
          {
            "value": "El Retiro",
            "label": "El Retiro"
          },
          {
            "value": "El Santuario",
            "label": "El Santuario"
          },
          {
            "value": "Entrerríos",
            "label": "Entrerríos"
          },
          {
            "value": "Envigado",
            "label": "Envigado"
          },
          {
            "value": "Fredonia",
            "label": "Fredonia"
          },
          {
            "value": "Frontino",
            "label": "Frontino"
          },
          {
            "value": "Giraldo",
            "label": "Giraldo"
          },
          {
            "value": "Girardota",
            "label": "Girardota"
          },
          {
            "value": "Gómez Plata",
            "label": "Gómez Plata"
          },
          {
            "value": "Granada",
            "label": "Granada"
          },
          {
            "value": "Guadalupe",
            "label": "Guadalupe"
          },
          {
            "value": "Guarne",
            "label": "Guarne"
          },
          {
            "value": "Guatapé",
            "label": "Guatapé"
          },
          {
            "value": "Heliconia",
            "label": "Heliconia"
          },
          {
            "value": "Hispania",
            "label": "Hispania"
          },
          {
            "value": "Itagüí",
            "label": "Itagüí"
          },
          {
            "value": "Ituango",
            "label": "Ituango"
          },
          {
            "value": "Jardín",
            "label": "Jardín"
          },
          {
            "value": "Jericó",
            "label": "Jericó"
          },
          {
            "value": "La Ceja",
            "label": "La Ceja"
          },
          {
            "value": "La Estrella",
            "label": "La Estrella"
          },
          {
            "value": "La Pintada",
            "label": "La Pintada"
          },
          {
            "value": "La Unión",
            "label": "La Unión"
          },
          {
            "value": "Liborina",
            "label": "Liborina"
          },
          {
            "value": "Maceo",
            "label": "Maceo"
          },
          {
            "value": "Marinilla",
            "label": "Marinilla"
          },
          {
            "value": "Medellín",
            "label": "Medellín"
          },
          {
            "value": "Montebello",
            "label": "Montebello"
          },
          {
            "value": "Murindó",
            "label": "Murindó"
          },
          {
            "value": "Mutatá",
            "label": "Mutatá"
          },
          {
            "value": "Nariño",
            "label": "Nariño"
          },
          {
            "value": "Nechí",
            "label": "Nechí"
          },
          {
            "value": "Necoclí",
            "label": "Necoclí"
          },
          {
            "value": "Olaya",
            "label": "Olaya"
          },
          {
            "value": "Peque",
            "label": "Peque"
          },
          {
            "value": "Pueblorrico",
            "label": "Pueblorrico"
          },
          {
            "value": "Puerto Berrío",
            "label": "Puerto Berrío"
          },
          {
            "value": "Puerto Nare",
            "label": "Puerto Nare"
          },
          {
            "value": "Puerto Triunfo",
            "label": "Puerto Triunfo"
          },
          {
            "value": "Remedios",
            "label": "Remedios"
          },
          {
            "value": "Rionegro",
            "label": "Rionegro"
          },
          {
            "value": "Sabanalarga",
            "label": "Sabanalarga"
          },
          {
            "value": "Sabaneta",
            "label": "Sabaneta"
          },
          {
            "value": "Salgar",
            "label": "Salgar"
          },
          {
            "value": "San Andrés de Cuerquia",
            "label": "San Andrés de Cuerquia"
          },
          {
            "value": "San Carlos",
            "label": "San Carlos"
          },
          {
            "value": "San Francisco",
            "label": "San Francisco"
          },
          {
            "value": "San Jerónimo",
            "label": "San Jerónimo"
          },
          {
            "value": "San José de la Montaña",
            "label": "San José de la Montaña"
          },
          {
            "value": "San Juan de Urabá",
            "label": "San Juan de Urabá"
          },
          {
            "value": "San Luis",
            "label": "San Luis"
          },
          {
            "value": "San Pedro de los Milagros",
            "label": "San Pedro de los Milagros"
          },
          {
            "value": "San Pedro de Urabá",
            "label": "San Pedro de Urabá"
          },
          {
            "value": "San Rafael",
            "label": "San Rafael"
          },
          {
            "value": "San Roque",
            "label": "San Roque"
          },
          {
            "value": "San Vicente",
            "label": "San Vicente"
          },
          {
            "value": "Santa Bárbara",
            "label": "Santa Bárbara"
          },
          {
            "value": "Santa Fe de Antioquia",
            "label": "Santa Fe de Antioquia"
          },
          {
            "value": "Santa Rosa de Osos",
            "label": "Santa Rosa de Osos"
          },
          {
            "value": "Santo Domingo",
            "label": "Santo Domingo"
          },
          {
            "value": "Segovia",
            "label": "Segovia"
          },
          {
            "value": "Sonsón",
            "label": "Sonsón"
          },
          {
            "value": "Sopetrán",
            "label": "Sopetrán"
          },
          {
            "value": "Támesis",
            "label": "Támesis"
          },
          {
            "value": "Tarazá",
            "label": "Tarazá"
          },
          {
            "value": "Tarso",
            "label": "Tarso"
          },
          {
            "value": "Titiribí",
            "label": "Titiribí"
          },
          {
            "value": "Toledo",
            "label": "Toledo"
          },
          {
            "value": "Turbo",
            "label": "Turbo"
          },
          {
            "value": "Uramita",
            "label": "Uramita"
          },
          {
            "value": "Urrao",
            "label": "Urrao"
          },
          {
            "value": "Valdivia",
            "label": "Valdivia"
          },
          {
            "value": "Valparaíso",
            "label": "Valparaíso"
          },
          {
            "value": "Vegachí",
            "label": "Vegachí"
          },
          {
            "value": "Venecia",
            "label": "Venecia"
          },
          {
            "value": "Vigía del Fuerte",
            "label": "Vigía del Fuerte"
          },
          {
            "value": "Yalí",
            "label": "Yalí"
          },
          {
            "value": "Yarumal",
            "label": "Yarumal"
          },
          {
            "value": "Yolombó",
            "label": "Yolombó"
          },
          {
            "value": "Yondó",
            "label": "Yondó"
          },
          {
            "value": "Zaragoza",
            "label": "Zaragoza"
          },
          {
            "value": "Pereira",
            "label": "Pereira"
          },
          {
            "value": "Armenia",
            "label": "Armenia"
          },
          {
            "value": "Bucaramanga",
            "label": "Bucaramanga"
          },
          {
            "value": "Yumbo",
            "label": "Yumbo"
          },
          {
            "value": "Jamundí",
            "label": "Jamundí"
          },
          {
            "value": "Cartagena",
            "label": "Cartagena"
          },
          {
            "value": "Villeta",
            "label": "Villeta"
          },
          {
            "value": "Bucaramanga",
            "label": "Bucaramanga"
          },
          {
            "value": "Floridablanca",
            "label": "Floridablanca"
          }
        ],
        value: "",
        name: "city",
        label: "¿En qué ciudad vives?",
        placeholder: "Selecciona tu Ciudad",
      },
      {
        type: "input",
        name: "address",
        label: "¿En qué dirección vives?",
        placeholder: "Ingresa tu dirección",
        value: "",
      },
    ],
  },
  civil_state: {
    title: "Estado civil",
    subTitle: "Los estados civiles en Colombia son los siguientes:",
    state: [
      {
        order: "h",
        type: "radio_btn",
        options: [
          {
            value: `Casado con sociedad conyugal vigente`,
            label: "Casado con sociedad conyugal vigente",
          },
          {
            value: `Casado sin sociedad conyugal vigente`,
            label: "Casado sin sociedad conyugal vigente",
          },
          {
            value: `Soltero con unión marital de hecho con sociedad patrimonial`,
            label:
              "Soltero con unión marital de hecho con sociedad patrimonial",
          },
          {
            value: `Soltero con unión marital de hecho sin sociedad patrimonial`,
            label:
              "Soltero con unión marital de hecho sin sociedad patrimonial",
          },
          {
            value: `Soltero, sin unión marital de hecho`,
            label: "Soltero sin unión marital de hecho",
          },
        ],
        name: "civil_state",
        helper: {
          link: "https://co-listing-images-v2.s3.amazonaws.com/ESTADOS+CIVILES.pdf",
          label: "¿Qué es esto?",
          tooltip: false,
        },
        label: "Selecciona el estado civil al que perteneces",
        variant: "square",
      },
    ],
  },
  childs: {
    title: "Sobre los hijos",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: [
          {
            value: `yes`,
            label: "Si",
          },
          {
            value: `no`,
            label: "No",
          },
        ],
        name: "children",
        label: "¿Tienes hijos actualmente?",
        variant: "rounded",
        sizeComponent: "102px",
        componentsChilds: [
          {
            order: "v",
            type: "radio_btn",
            options: [
              {
                value: `yes`,
                label: "Si",
              },
              {
                value: `no`,
                label: "No",
              },
            ],
            name: "adult_children",
            label: "Al día de hoy ¿Alguno de tus hijos es menor de edad?",
            variant: "rounded",
            sizeComponent: "102px",
          },
        ],
      },
    ],
  },
  about_property: {
    title: "Sobre el inmueble",
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: [
          {
            value: `yes`,
            label: "Si",
          },
          {
            value: `no`,
            label: "No",
          },
        ],
        name: "about_property",
        label: "¿Tu inmueble está en arriendo en este momento?",
        variant: "rounded",
        sizeComponent: "140px",
        componentsChilds: [
          {
            order: "v",
            type: "date_picker",
            value: new Date().toISOString(),
            options: [
              {
                value: `yes`,
                label: "Si",
              },
              {
                value: `no`,
                label: "No",
              },
            ],
            name: "about_property_date",
            label: "¿Hasta que fecha estará en arriendo el inmueble?",
            subLabel:
              "Esta fecha es muy importante para el proceso, trata de ser lo más preciso posible.",
            variant: "rounded",
            placeholder: "DD/MM/AAAA",
          },
        ],
      },
    ],
  },
  structural_failures: {
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: [
          {
            value: `yes`,
            label: "Si",
          },
          {
            value: `no`,
            label: "No",
          },
        ],
        name: "structural_failures",
        label:
          "¿En algún momento has sido notificado de que tu inmueble tiene o tuvo fallas estructurales, arquitectónicas o de construcción que afecten la estabilidad de (los) inmueble(s), edificio, torre, interior o conjunto?",
        helper: {
          link: "",
          label: "¿Qué es esto?",
          tooltip: true,
          content:
            "Se refiere a una deficiencia fundamental en la construcción o diseño de la edificación, que afecta negativamente la habitabilidad del inmueble y la calidad de vida de los residentes. Estos problemas pueden ser de naturaleza física, como la presencia de grietas en las estructuras, problemas de cimientos e incluso inclinaciones notorias.",
        },
        variant: "rounded",
      },
    ],
  },
  price_minimal_inmobi: {
    title: "Condiciones habinmobiliaria",
    backgroundColor: true,
    state: [
      {
        order: "v",
        type: "radio_btn",
        options: [
          {
            value: `yes`,
            label: "Si",
          },
          {
            value: `no`,
            label: "No",
          },
        ],
        name: "price_minimal_inmobi",
        label:
          "¿Ha sido informado del precio mínimo establecido para venta con habi inmobiliaria de $ 100.000.000 ? ",
        variant: "rounded",
      },
    ],
  },
};
