import { FEEDBACK_MESSAGES } from "constants/form";
import { fieldTypeEnum, IConfig } from "models";
import {
  optionsCivilStateMx,
  optionsTypeIdentificationMx,
  OPTIONS_MINICIPIO,
  OPTIONS_PROVINCIA,
} from "./constants";

export const formConfigMx: IConfig[] = [
  {
    name: "nombre_del_cliente",
    label: "Nombre",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Nombre",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "apellido_paterno_propietario",
    label: "Apellido paterno",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Apellido paterno ",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "apellido_materno_propietario",
    label: "Apellido materno",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Apellido materno",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "nacionalidad_cliente",
    label: "Nacionalidad",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Nacionalidad",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "estado_civil_del_cliente",
    label: "Estado civil",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.select,
    placeholder: "Estado civil",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
    options: optionsCivilStateMx,
  },
  {
    name: "rfc_cliente",
    label: "RFC",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "RFC",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },

  {
    name: "curp_cliente",
    label: "CURP",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "CURP",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "tipo_de_identificacion_del_cliente",
    label: "Tipo de identificación",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.select,
    placeholder: "Tipo de identificación",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
    options: optionsTypeIdentificationMx,
  },
  {
    name: "numero_de_identificacion_cliente",
    label: "Número de identificación",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Número de identificación",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "correo_electronico_del_cliente",
    label: "Correo electrónico",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Correo",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "estado_o_provincia",
    label: "Estado o provincia de residencia",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.select,
    placeholder: "Estado o provincia de residencia",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
    options: OPTIONS_PROVINCIA,
  },
  {
    name: "municipio",
    label: "Municipio de residencia",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.select,
    placeholder: "Municipio de residencia",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
    options: OPTIONS_MINICIPIO,
  },
  {
    name: "colonia",
    label: "Colonia de residencia",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Colonia de residencia",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "calle_avenida_via",
    label: "Calle, avenida o vía principal de residencia",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Calle, avenida o vía principal de residencia",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },

  {
    name: "numero_exterior",
    label: "Número exterior de dirección de residencia",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Número exterior de dirección de residencia",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
  {
    name: "numero_interior",
    label: "Número interior de dirección de residencia",
    value: "",
    type: "text",
    fieldType: fieldTypeEnum.text,
    placeholder: "Número interior de dirección de residencia",
    validation: {
      type: "string",
      settings: [
        {
          type: "required",
          message: FEEDBACK_MESSAGES.REQUIRED,
        },
      ],
    },
  },
];
