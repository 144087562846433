import { FC } from "react";

// Components
import HeaderCo from "../HeaderCo/HeaderCo";
import AuthorizationParagraphCo from "../AuthorizationParagraphCo/AuthorizationParagraphCo";

// Styles
import { TermsWrapper } from "../TermsWrapper";

// helpers
import { getPlural } from "utils/scripts/getPlural";
import { formatDateCo } from "utils/scripts/formatDateCo";

interface TermsCoProps {
  data: {
    interior: string;
    direccion: string;
    escrituracion: any[];
    valor_de_las_arras: string;
    telefono_del_cliente: string;
    numero_de_apartamento: string;
    valor_de_la_separacion: string;
    estado_civil_del_cliente: string;
    fecha_de_pago_de_las_arras: string;
    metodo_de_pago_de_las_arras: string;
    nombre_completo_del_cliente: string;
    nombre_de_edificio_conjunto: string;
    precio_de_transaccion_acordado: string;
    correo_electronico_del_cliente: string;
    fecha_de_pago_de_la_separacion: string;
    metodo_de_pago_de_la_separacion: string;
    numero_de_identificacion_cliente: string;
    ciudad_de_residencia_del_cliente: string;
    tipo_de_identificacion_del_cliente: string;
    direccion_de_residencia_para_notificacion: string;
  };
}

const TermsCo: FC<TermsCoProps> = ({ data }) => {
  return (
    <TermsWrapper>
      <main className="content">
        <HeaderCo />
        <section className="content-header">
          <h1 className="content-header-title">
            Bogotá D.C, {formatDateCo(new Date().toDateString())}
          </h1>
          <div className="content-header-intro">
            <p>Señores</p>
            <p>Habi</p>
            <p>Ref. Oferta de Compra</p>
          </div>
        </section>
        <section className="content-main">
          De acuerdo con la visita realizada{" "}
          {data?.numero_de_apartamento
            ? `apartamento ${data?.numero_de_apartamento}`
            : ""}{" "}
          {data?.nombre_de_edificio_conjunto
            ? `del conjunto ${data?.nombre_de_edificio_conjunto}`
            : ""}{" "}
          {data?.interior ? `interior ${data?.interior} ` : ""}
          ubicado en la dirección <strong>{data?.direccion}</strong> queremos
          realizar una oferta de compra por valor de{" "}
          <strong>{data?.precio_de_transaccion_acordado}</strong> pagaderos así:
        </section>
        <ol className="content-terms">
          <li className="content-term">
            A la firma de la carta de intención la suma de $
            {data?.valor_de_la_separacion}, pagaderos en{" "}
            {data?.metodo_de_pago_de_la_separacion}, la cual será pagada el día{" "}
            {data?.fecha_de_pago_de_la_separacion}.
          </li>
          <li className="content-term">
            A la firma de la promesa de compraventa la suma de $
            {data?.valor_de_las_arras}, pagaderos en{" "}
            {data?.metodo_de_pago_de_las_arras}, la cual será firmada el día{" "}
            {data?.fecha_de_pago_de_las_arras}.
          </li>
          <li className="content-term">
            A la firma de la escritura pública, {data?.escrituracion?.length}{" "}
            pago
            {getPlural(data?.escrituracion, "s")}, que se debe
            {getPlural(data?.escrituracion, "n")} pagar de la siguiente manera:
            <ul className="content-term-list">
              {data?.escrituracion?.map(
                ({ valor, tipo, banco, metodo }, index) => (
                  <li key={`${valor}_${index}`}>
                    Pago {index + 1}: La suma de ${valor} pagaderos a través de
                    "{tipo}", de la entidad "{banco}", por medio de "{metodo}".
                  </li>
                )
              )}
            </ul>
          </li>
        </ol>
      </main>
      <h3 className="content-conclusion">Atentamente,</h3>
      <footer className="footer">
        <div className="footer-properties">
          <div className="footer-property">
            <label className="footer-property-label">
              Tipo de identificación
            </label>
            <h2 className="footer-property-value">
              {data?.tipo_de_identificacion_del_cliente}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Documento de identificación
            </label>
            <h2 className="footer-property-value">
              {data?.numero_de_identificacion_cliente}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">Estado civil</label>
            <h2 className="footer-property-value">
              {data?.estado_civil_del_cliente}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Ciudad de residencia del cliente
            </label>
            <h2 className="footer-property-value">
              {data?.ciudad_de_residencia_del_cliente}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">
              Dirección de residencia para notificación
            </label>
            <h2 className="footer-property-value">
              {data?.direccion_de_residencia_para_notificacion}
            </h2>
          </div>
          <div className="footer-property">
            <label className="footer-property-label">Correo Electrónico</label>
            <h2 className="footer-property-value">
              {data?.correo_electronico_del_cliente}
            </h2>
          </div>
        </div>
      </footer>
    </TermsWrapper>
  );
};

export default TermsCo;
