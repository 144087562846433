import { FC } from "react";

// Components

// Styles

import { RadioButtonWrapper } from "./styles";

// helpers

interface RadioButtonProps {
  handleChange: (key: string, value: any) => void;
  value: string;
  label: string;
  name: string;
}

const RadioButton: FC<RadioButtonProps> = ({
  handleChange,
  value,
  label,
  name,
}) => {
  return (
    <RadioButtonWrapper>
      <label htmlFor={name}>{label}</label>
      <input
        type="radio"
        id={name}
        value={value}
        name={name}
        onChange={(e) => handleChange(name, e.target.value)}
      />
    </RadioButtonWrapper>
  );
};

export default RadioButton;
