import axios from "axios";

const apiKey = process.env.REACT_APP_HABI_360_API_KEY;

export const earn = (url, params) =>
  axios
    .get(url, {
      headers: {
        "Content-Type": "application/json",
        "x-api-key": apiKey,
      },
      params: { ...params },
      validateStatus: (status) => status >= 200 && status < 400,
    })
    .then((res) => {
      if (res.data.data.length > 0) return res.data.data;
      return res.data.data;
    })
    .catch((e) => e.response.data);
